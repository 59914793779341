import getApi from "apis/get.api";
import Bar from "Components/charts/Bar";
import TreeMap from "Components/charts/TreeMap";
import { handleError } from "helpers/commonFunctions";
import { useEffect, useState } from "react";
import { Card, CardBody, Spinner } from "reactstrap";

interface Props {
    fetchChartTwo : boolean;
    fetchChartThree() : void;
}
const MerchantsCountriesChart = ({fetchChartTwo,fetchChartThree} : Props) => {
    const [values, setValues] = useState<any>([]);

    useEffect(() => {
        if(fetchChartTwo) {
            getApi(`/admin/report/charts/tow`).then((response) => {
                setValues(response.data.data.values);
                fetchChartThree()
            }).catch((error) => {
                handleError(error);
                fetchChartThree()
            });   
        }
    },[fetchChartTwo])
       
    return <Card>
    <div className="border-1 align-items-center d-flex card-header">
        <h4 className="card-title mb-0 flex-grow-1">Merchants Per Country Chart</h4>
    </div>
    <CardBody>
        {values.length == 0 && <div className='d-flex justify-content-center p-5'>
            <Spinner className=''/>
        </div>} 
        {values.length > 1 && <TreeMap 
            enableDataLabels={false} 
            distributed={false} 
            horizontal={false}  
            values={values} 
            dataColors={null}
        />}
    </CardBody>
</Card>
}

export default MerchantsCountriesChart