import React, { useEffect, useState } from "react";
import {
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    Spinner,
    TabContent,
    TabPane,
    UncontrolledButtonDropdown,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import classnames from "classnames";
import { useParams } from "react-router-dom";
import Transactions from "./resellerComponents/Transactions";
import Vouchers from "./resellerComponents/Vouchers";
import getApi from "../../../apis/get.api";
import { toast } from "react-toastify";
import { ResellerInterface } from "../../../Interfaces/Interface";
import putApi from "../../../apis/put.api";
import Select from "react-select";
import DualListBox from "react-dual-listbox";
import "react-dual-listbox/lib/react-dual-listbox.css";
import postApi from "../../../apis/post.api";

const EditPage = () => {
    document.title = "Admin | Resellers Edit";
    const [isLoading, setIsLoading] = useState(false);
    const [countries, setCountries] = useState<any>(null);
    const [defaultCountry, setDefaultCountry] = useState<any>();
    const [resellerInformation, setResellerInformation] = useState<ResellerInterface>({
        resellerCountryName : '',
        resellerEmail: "",
        resellerId: 0,
        resellerPhoneNumber: '',
        resellerStatus : "",
        resellerUsername: "",
        resellerWebsiteUrl: "",
        resellerCountryId: 0,
        resellerBalance : 0
    });
    const [activeTab, setActiveTab] = useState("1");
    const { id } = useParams();
    const [selectedOptGroup, setSelectedOptGroup] = useState<any>([]);
    const [optgroup,setOptgroup] = useState([])
    const [userAction,setUserAction] = useState(false)

    const tabChange = (tab: any) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    useEffect(() => {
        getApi(`/admin/reseller/${id}`)
            .then((response: any) => {
                const profileData = response.data.data;
                setResellerInformation(profileData);
                getApi('/common/get/countries').then((response: any) => {
                    const countriesList = response.data.data.map((country: any) => ({
                        label: country.name,
                        value: country.id,
                    }));

                    // Loop through countriesList directly instead of countriesList.array
                    countriesList.forEach((country: any) => {
                        if (country.value === profileData.resellerCountryId) {
                            setDefaultCountry({
                                label: country.label,
                                value: country.value // Correct the value here too
                            });
                        }
                    });

                    setCountries(countriesList);
                })
                .catch((error) => {
                    toast.error('General error occurred');
                    throw error;
                });
            })
            .catch((error: any) => {
                toast.error("There was an issue while fetching reseller information, please reload the page");
            });
    }, []);

    const resellerStatusUpdate = (status : string) => {
        putApi(`/admin/reseller/edit/${id}`,{status : status}).then(response => {
            toast.success('Reseller status has been updated')
            window.location.reload()
        }).catch(error => {
            toast.error('Something went wrong while processing your request')
        })
    }

    // Function to render dropdown options based on resellerStatus
    const renderDropdownOptions = () => {
        switch (resellerInformation?.resellerStatus) {
            case "a": // Approved
                return (
                    <>
                        <DropdownItem onClick={() => {resellerStatusUpdate('r')}}> Reject </DropdownItem>
                        <DropdownItem onClick={() => {resellerStatusUpdate('b')}}> Block </DropdownItem>
                    </>
                );
            case "b": // Blocked
                return (
                    <>
                        <DropdownItem onClick={() => {resellerStatusUpdate('r')}}> Reject </DropdownItem>
                        <DropdownItem onClick={() => {resellerStatusUpdate('a')}}> Approve </DropdownItem>
                    </>
                );
            case "r": // Rejected
                return (
                    <>
                        <DropdownItem onClick={() => {resellerStatusUpdate('a')}}> Approve </DropdownItem>
                        <DropdownItem onClick={() => {resellerStatusUpdate('b')}}> Block </DropdownItem>
                    </>
                );
            case "p": // Pending
                return (
                    <>
                        <DropdownItem onClick={() => {resellerStatusUpdate('r')}}> Reject </DropdownItem>
                        <DropdownItem onClick={() => {resellerStatusUpdate('b')}}> Block </DropdownItem>
                        <DropdownItem onClick={() => {resellerStatusUpdate('a')}}> Approve </DropdownItem>
                    </>
                );
            default:
                return null;
        }
    };

    const changeCountry = (element: any) => {
        setResellerInformation({
            ...resellerInformation,
            ['resellerCountryId']: element.value,
        });
    };

    const handleUpdate = () => {
        setIsLoading(true); // Start loading
        putApi(`/admin/reseller/update/${id}`, resellerInformation)
            .then(() => {
                toast.success("Reseller profile updated successfully");
            })
            .catch(() => {
                toast.error("Failed to update reseller profile");
            })
            .finally(() => {
                setIsLoading(false); 
            });
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setResellerInformation({
            ...resellerInformation,
            [e.target.name]: e.target.value,
        });
    };

    useEffect(() => {
        if(optgroup.length === 0){
            getApi('/admin/reseller/merchants/' + id).then(response => {
                const merchantsSelectList : any = [];
                const selectedMerchantSelectList : any = [];
                response.data.data.merchants.forEach((merchant : any) => {
                    merchantsSelectList.push({
                        value : merchant.merchantUsername,
                        label : merchant.merchantUsername,
                        id : merchant.merchantId,
                    })
                })
                setOptgroup(merchantsSelectList)
                response.data.data.resellerMerchants.forEach((merchant : any) => {
                    selectedMerchantSelectList.push(merchant.merchantUsername)
                })
                setSelectedOptGroup(selectedMerchantSelectList)
            }).catch(error => {

            })
        }

    },[])

    useEffect(() => {
        if(userAction){
            postApi('/admin/reseller/set/' + id,{"merchants" : selectedOptGroup}).then((response => {
                toast.success('Process has been done successfully')
                setUserAction(false)
            })).catch((error => {
                toast.error('There was an issue while processing your request, please try again')
                setUserAction(false)
            }))
        }
    }, [selectedOptGroup,userAction]);

    const change = (event: any) => {
        setUserAction(true)
        // Check if event is an array
        if (Array.isArray(event)) {
            // Set the entire event array as the new selectedOptGroup
            setSelectedOptGroup(event);
        } else {
            // Spread the current selectedOptGroup and add the single event
            setSelectedOptGroup([...selectedOptGroup, event]);
        }
    };


    return (
        <div className="page-content">
            <BreadCrumb title={`${resellerInformation?.resellerUsername}`} pageTitle="Reseller List" url={"/reseller/list"} />
            <Container fluid={true}>
                <div className="d-flex justify-content-between w-100 mb-3">
                    <div>
                        {resellerInformation && resellerInformation.resellerStatus === "p" && (
                            <div className="alert-border-left shadow alert alert-info alert-dismissible fade show" role="alert">
                                <i className="ri-airplay-line me-3 align-middle fs-16"></i>
                                <strong>Pending Application </strong> - This reseller has pending application, in order for him to use our
                                service his application has to be approved
                            </div>
                        )}
                        {resellerInformation && resellerInformation.resellerStatus === "b" && (
                            <div className="alert-border-left shadow alert alert-danger alert-dismissible fade show" role="alert">
                                <i className="ri-airplay-line me-3 align-middle fs-16"></i>
                                <strong>Blocked Application </strong> - This reseller has blocked application, in order for him to use our
                                service his application has to be approved
                            </div>
                        )}
                        {resellerInformation && resellerInformation.resellerStatus === "r" && (
                            <div className="alert-border-left shadow alert alert-danger alert-dismissible fade show" role="alert">
                                <i className="ri-airplay-line me-3 align-middle fs-16"></i>
                                <strong>Rejected Application </strong> - This reseller has rejected application, in order for him to use our
                                service his application has to be approved
                            </div>
                        )}
                    </div>
                </div>
                <div className="d-flex gap-3 w-100">
                    <Card className="w-100">
                        <CardHeader>
                            <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === "1" })}
                                        onClick={() => {
                                            tabChange("1");
                                        }}
                                    >
                                        <i className="fas fa-home"></i>
                                        Transactions
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        to="#"
                                        className={classnames({ active: activeTab === "2" })}
                                        onClick={() => {
                                            tabChange("2");
                                        }}
                                        type="button"
                                    >
                                        <i className="far fa-user"></i>
                                        Generated Vouchers
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        to="#"
                                        className={classnames({ active: activeTab === "3" })}
                                        onClick={() => {
                                            tabChange("3");
                                        }}
                                        type="button"
                                    >
                                        <i className="far fa-envelope"></i>
                                        Profile
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        to="#"
                                        className={classnames({ active: activeTab === "4" })}
                                        onClick={() => {
                                            tabChange("4");
                                        }}
                                        type="button"
                                    >
                                        <i className="far fa-envelope"></i>
                                        Reseller Actions
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        to="#"
                                        className={classnames({ active: activeTab === "5" })}
                                        onClick={() => {
                                            tabChange("5");
                                        }}
                                        type="button"
                                    >
                                        <i className="far fa-envelope"></i>
                                        Reseller Limitation
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </CardHeader>
                        <CardBody className="p-4">
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    {resellerInformation && resellerInformation.resellerId > 0  && <Transactions resellerEmail={resellerInformation.resellerEmail} />}
                                </TabPane>
                                <TabPane tabId="2">
                                    {resellerInformation && resellerInformation.resellerId > 0 && <Vouchers resellerId={resellerInformation.resellerId} />}
                                </TabPane>
                                <TabPane tabId="3">
                                    {resellerInformation && (
                                        <Row className={"gap-3"}>
                                            <Col md={12} xs={12} lg={4} xl={3} className={""}>
                                                <Label for="fullnameInput" className="form-label">
                                                    Reseller Name
                                                </Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    name="resellerUsername"
                                                    placeholder="Enter your reseller name"
                                                    value={resellerInformation.resellerUsername}
                                                    onChange={handleInputChange}
                                                />
                                            </Col>
                                            <Col md={12} xs={12} lg={4} xl={3} className={""}>
                                                <Label for="inputEmail4" className="form-label">
                                                    Reseller Email
                                                </Label>
                                                <Input
                                                    type="email"
                                                    className="form-control"
                                                    name="resellerEmail"
                                                    placeholder="Email"
                                                    value={resellerInformation.resellerEmail}
                                                    onChange={handleInputChange}
                                                />
                                            </Col>
                                            {defaultCountry && <Col md={12} xs={12} lg={4} xl={3}>
                                                <Label for="merchantCountryName" className="form-label">Merchant Country</Label>
                                                <Select
                                                    options={countries}
                                                    id="choices-single-default"
                                                    className="js-example-basic-single mb-0"
                                                    name="country_id"
                                                    defaultValue={defaultCountry}
                                                    onChange={changeCountry}
                                                />
                                            </Col>}
                                            <Col md={12} xs={12} lg={4} xl={3} className={""}>
                                                <Label for="inputAddress" className="form-label">
                                                    Reseller Website Or Trader Name
                                                </Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    name="resellerWebsiteUrl"
                                                    value={resellerInformation.resellerWebsiteUrl}
                                                    placeholder="Reseller Website Or Trader Name"
                                                    onChange={handleInputChange}
                                                />
                                            </Col>
                                            <Col md={12} xs={12} lg={4} xl={3} className={""}>
                                                <Label for="resellerPhoneNumber" className="form-label">
                                                    Reseller Phone Number
                                                </Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="resellerPhoneNumber"
                                                    name="resellerPhoneNumber"
                                                    value={resellerInformation.resellerPhoneNumber}
                                                    placeholder="Reseller Phone Number"
                                                    onChange={handleInputChange}
                                                />
                                            </Col>
                                            <Col md={12} xs={12} lg={4} xl={3} className={""}>
                                                <Label for="resellerBalance" className="form-label">
                                                    Reseller Wallet Balance
                                                </Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="resellerBalance"
                                                    name="resellerBalance"
                                                    value={resellerInformation.resellerBalance}
                                                    readOnly={true}
                                                    disabled={true}
                                                />
                                            </Col>
                                        </Row>
                                    )}
                                    <div className="hstack gap-2 justify-content-end">
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={handleUpdate}
                                            disabled={isLoading} // Disable button while loading
                                        >
                                            {isLoading ? <Spinner size="sm" /> : "Update"} {/* Loader inside button */}
                                        </button>
                                    </div>
                                </TabPane>
                                <TabPane tabId="4" className={""}>
                                    <div className="row bg-transparent">
                                        <div className={"col-12"}>
                                            <ButtonGroup>
                                                <UncontrolledButtonDropdown id="btnGroupDrop1">
                                                    <DropdownToggle color="primary" caret size={"lg"}>
                                                        Reseller Actions
                                                    </DropdownToggle>
                                                    <DropdownMenu>{renderDropdownOptions()}</DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            </ButtonGroup>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tabId="5" className={""}>
                                    {optgroup && <div className="row">
                                        <div className="col-12">
                                            <DualListBox
                                                canFilter
                                                filterCallback={(optgroup: any, filterInput: any) => {
                                                    if (filterInput === "") {
                                                        return true;
                                                    }

                                                    return new RegExp(filterInput, "i").test(optgroup.label);
                                                }}
                                                filterPlaceholder="Search..."
                                                options={optgroup}
                                                selected={selectedOptGroup}
                                                onChange={(e : any) => change(e)}
                                                icons={{
                                                    moveLeft: <span className="mdi mdi-chevron-left" key="key"/>,
                                                    moveAllLeft: [
                                                        <span className="mdi mdi-chevron-double-left" key="key"/>,
                                                    ],
                                                    moveRight: <span className="mdi mdi-chevron-right" key="key"/>,
                                                    moveAllRight: [
                                                        <span className="mdi mdi-chevron-double-right" key="key"/>,
                                                    ],
                                                    moveDown: <span className="mdi mdi-chevron-down" key="key"/>,
                                                    moveUp: <span className="mdi mdi-chevron-up" key="key"/>,
                                                    moveTop: (
                                                        <span className="mdi mdi-chevron-double-up" key="key"/>
                                                    ),
                                                    moveBottom: (
                                                        <span className="mdi mdi-chevron-double-down" key="key"/>
                                                    ),
                                                }}
                                            />
                                        </div>
                                    </div>}
                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Card>
                </div>
            </Container>
        </div>
    );
};

export default EditPage;