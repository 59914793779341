import React, {useEffect, useState} from 'react';
import {Container, Input, Label, Modal, Spinner, ModalHeader , ModalBody} from "reactstrap";
import Select from "react-select";
import getApi from "../../../apis/get.api";
import postApi from "../../../apis/post.api";
import {toast} from "react-toastify";

const CreateVoucher = () => {
    document.title = "Voucher | Create Voucher";
    const [merchants,setMerchants] = useState(null)
    const [resellers,setResellers] = useState(null)
    const [vouchers,setVouchers] = useState([])
    const [disabledGenerateButton,setDisabledGenerateButton] = useState(false)
    const [merchant,setMerchant] = useState(null)
    const [reseller,setReseller] = useState(null)
    const [amount,setAmount] = useState<any>(null)
    const [showVoucherModel, setShowVoucherModel] = useState<boolean>(false);
    const [loading,setLoading] = useState(false)
    const [generatedVoucherInfo,setGeneratedVoucherInfo] = useState<any>(null);

    useEffect(() => {
        fetchCreatePage();
    },[])

    const fetchCreatePage = () => {
        getApi('/admin/voucher/create').then((response) => {
            let merchantsList : any = [];
            response.data.data.merchants.forEach((merchant : any) => {
                merchantsList.push({
                    label : merchant.merchantName,
                    value : merchant.merchantId
                })
            })
            setMerchants(merchantsList)

            let resellersList : any = [];
            response.data.data.resellers.forEach((reseller : any) => {
                resellersList.push({
                    label : reseller.resellerName,
                    value : reseller.resellerId
                })
            })
            setResellers(resellersList)

            let vouchersData : any = [];
            response.data.data.vouchers.forEach((voucher : any) => {
                vouchersData.push({
                    voucherId : voucher.voucherId,
                    voucherCode : voucher.voucherCode,
                    voucherOrderId : voucher.voucherOrderId,
                    voucherAmount : voucher.voucherAmount,
                    voucherStatus : voucher.voucherStatus,
                    voucherExpiry : voucher.voucherExpiryDate,
                    voucherMerchantName : voucher.merchantName
                })
            })
            setVouchers(vouchersData)
        }).catch((error) => {
            toast.error(error.response.data.message);
        })
    }

    const handleAmount = (event : any) => {
        var amountValue : any = parseInt(event.target.value)
        if(event.target.value === ''){
            amountValue = '';
        }
        setAmount(amountValue);
    }

    const handleMerchant = (event : any) => {
        setMerchant(event.value)
    }

    const handleReseller = (event : any) => {
        setReseller(event.value)
    }

    useEffect(() => {
        if(amount && merchant && reseller){
            setDisabledGenerateButton(false)
        }else{
            setDisabledGenerateButton(true)
        }
    }, [amount,merchant,reseller]);

    const generateVoucher = () => {
        setLoading(true)
        const payload = {
            merchantId : merchant,
            resellerId : reseller,
            amount : amount
        };
        postApi('/admin/voucher/create',payload).then((response) => {
            setGeneratedVoucherInfo(response.data.data)
            toast.success('Voucher has been created successfully');
            setLoading(false)
            setShowVoucherModel(true)
            fetchCreatePage();
        }).catch((error) => {
            toast.error('There was an issue while generating the requested voucher');
            setShowVoucherModel(false)
            setLoading(false)
        })
    }

    function tog_center() {
        setShowVoucherModel(!showVoucherModel);
    }

    return <div className="page-content">
        <Container fluid={true}>
            <div className="rounded-3">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="card h-100">
                            <div className="card-header">
                                Latest 10 Generated Vouchers
                            </div>
                            <div className="table-responsive table-card card-body">
                                <table className="table align-middle table-nowrap table-striped-columns mb-0">
                                    <thead className="table-light">
                                    <tr>
                                        <th scope="col">Voucher ID</th>
                                        <th scope="col">Voucher Merchant</th>
                                        <th scope="col">Voucher Code</th>
                                        <th scope="col">Voucher Expiration</th>
                                        <th scope="col">Voucher Amount</th>
                                        <th scope="col">Voucher Status</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {vouchers && vouchers.length > 0 && vouchers.map((voucher : any) => {
                                            return <tr>
                                                <td><span className="fw-medium">#{voucher.voucherId}</span></td>
                                                <td>{voucher.voucherMerchantName}</td>
                                                <td>{voucher.voucherCode}</td>
                                                <td>{voucher.voucherExpiry}</td>
                                                <td>${voucher.voucherAmount}</td>
                                                <td>{voucher.voucherStatus.toString().toUpperCase()}</td>
                                            </tr>
                                        })}
                                        {vouchers && vouchers.length === 0 && <tr>
                                            <td className="text-center p-4" colSpan={6}>No Vouchers Created Yet</td>
                                        </tr>}
                                        {!vouchers && <tr>
                                            <td className="text-center p-4" colSpan={6}><Spinner/></td>
                                        </tr>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="card h-100">
                            <div className="card-header">
                                Generate Voucher Form
                            </div>
                            {merchants && <div className="card-body">
                                <div className="">
                                    <h6>Select Merchant To Generate Voucher For</h6>
                                    <Select
                                        options={merchants}
                                        id="choices-single-default"
                                        className="js-example-basic-single mb-0"
                                        name="merchant"
                                        onChange={handleMerchant}
                                    />
                                </div>
                                <div className="mt-2">
                                    <h6>Select Reseller To Generate Voucher Form</h6>
                                    <Select
                                        options={resellers}
                                        id="choices-single-default"
                                        className="js-example-basic-single mb-0"
                                        name="merchant"
                                        onChange={handleReseller}
                                    />
                                </div>
                                <div className="mt-2">
                                    <Label>Voucher Amount</Label>
                                    <div className="input-group">
                                        <div className="input-group-text">$</div>
                                        <Input type="number" min={1} max={9999} value={amount} onChange={handleAmount}
                                               className="form-control" id="autoSizingInputGroup"
                                               placeholder="Voucher Amount"/>
                                    </div>
                                </div>
                            </div>}
                            <div className='card-footer'>
                                <div className={`mt-3 ${loading ? 'text-center' : ''}`}>
                                    {!loading && <button className="btn btn-primary w-100" onClick={generateVoucher}
                                                         disabled={disabledGenerateButton}>Generate Voucher</button>}
                                    {loading && <Spinner/>}
                                </div>
                            </div>
                            {!merchants && <div className="text-center m-5"><Spinner/></div>}
                        </div>
                    </div>
                </div>
            </div>
            {generatedVoucherInfo && <Modal isOpen={showVoucherModel} toggle={() => {
                tog_center();
            }} centered>
                <ModalHeader className="modal-title"/>
                <ModalBody className="text-center">
                    <i className="ri-thumb-up-line display-5 text-success"></i>
                    <div className="mt-1">
                    <h4 className="mb-1">Voucher has been created successfully</h4>
                        <p className="text-muted mb-4"> A new voucher has been created, please find the voucher
                            information down below</p>
                        <div className={'text-start'}>
                            <label>Voucher Merchant</label>
                            <input type={"text"} disabled={true} readOnly={true} value={generatedVoucherInfo.merchantName}
                                   className={"form-control"}/>
                        </div>
                        <div className={'text-start mt-3'}>
                            <label>Voucher Code</label>
                            <input type={"text"} disabled={true} readOnly={true} value={generatedVoucherInfo.voucherCode}
                                   className={"form-control"}/>
                        </div>
                        <div className={'mt-3 text-start'}>
                            <label>Voucher Expiry Date</label>
                            <input type={"text"} disabled={true} readOnly={true} value={generatedVoucherInfo.voucherExpiryDate}
                                   className={"form-control"}/>
                        </div>
                        <div className={'mt-3 text-start'}>
                            <label>Voucher Amount</label>
                            <input type={"text"} disabled={true} readOnly={true} value={`$${parseInt(generatedVoucherInfo.voucherAmount)}`}
                                   className={"form-control"}/>
                        </div>
                    </div>
                </ModalBody>
            </Modal>}
        </Container>
    </div>
}

export default CreateVoucher;