import { useEffect, useState } from "react"
import CountUp from "react-countup"

const Counter = ({responseData,merchantInformation} : any) => {
    return <div className="card crm-widget">
        <div className="card-body p-0">
            <div className="row row-cols-xxl-5 row-cols-md-3 row-cols-1 g-0">
                <div className="col" >
                    <div className="py-4 px-3">
                        <h5 className="text-muted text-uppercase fs-13">Balance To Withdrawal<i
                            className={" fs-18 float-end align-middle"}></i></h5>
                        <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                                <i className={"display-6 text-muted"}></i>
                            </div>
                            <div className="flex-grow-1">
                                <h2 className="mb-0">
                                    <span className="counter-value" data-target="197">
                                    <CountUp
                                        start={0}
                                        prefix={"$"}
                                        suffix={""}
                                        separator={","}
                                        end={responseData.balance ?? 0}
                                        decimals={2}
                                        duration={2}
                                    />
                                    </span>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col" >
                    <div className="py-4 px-3 ms-3">
                        <h5 className="text-muted text-uppercase fs-13">Pre-loaded Balance<i
                            className={" fs-18 float-end align-middle"}></i></h5>
                        <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                                <i className={"display-6 text-muted"}></i>
                            </div>
                            <div className="flex-grow-1">
                                <h2 className="mb-0">
                                    <span className="counter-value" data-target="197">
                                    <CountUp
                                        start={0}
                                        prefix={"$"}
                                        suffix={""}
                                        separator={","}
                                        end={responseData.secondlyBalance ?? 0}
                                        decimals={2}
                                        duration={4}
                                    />
                                    </span>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col" >
                    <div className="py-4 px-3 ms-3">
                        <h5 className="text-muted text-uppercase fs-13">Redeemed ClvrPay Vouchers<i
                            className={" fs-18 float-end align-middle"}></i></h5>
                        <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                                <i className={"display-6 text-muted"}></i>
                            </div>
                            <div className="flex-grow-1">
                                <h2 className="mb-0">
                                    <span className="counter-value" data-target="197">
                                    <CountUp
                                        start={0}
                                        prefix={"$"}
                                        suffix={""}
                                        separator={","}
                                        end={responseData.clvrPayVouchersAmount ?? 0}
                                        decimals={2}
                                        duration={4}
                                    />
                                    </span>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col" >
                    <div className="py-4 px-3 ms-3">
                        <h5 className="text-muted text-uppercase fs-13">Redeemed {merchantInformation.merchantUsername} Vouchers<i
                            className={" fs-18 float-end align-middle"}></i></h5>
                        <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                                <i className={"display-6 text-muted"}></i>
                            </div>
                            <div className="flex-grow-1">
                                <h2 className="mb-0">
                                    <span className="counter-value" data-target="197">
                                    <CountUp
                                        start={0}
                                        prefix={"$"}
                                        suffix={""}
                                        separator={","}
                                        end={responseData.noneClvrPayVouchersAmount ?? 0}
                                        decimals={2}
                                        duration={4}
                                    />
                                    </span>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col" >
                    <div className="py-4 px-3 ms-3">
                        <h5 className="text-muted text-uppercase fs-13">Active {merchantInformation.merchantUsername} Vouchers<i
                            className={" fs-18 float-end align-middle"}></i></h5>
                        <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                                <i className={"display-6 text-muted"}></i>
                            </div>
                            <div className="flex-grow-1">
                                <h2 className="mb-0">
                                    <span className="counter-value" data-target="197">
                                    <CountUp
                                        start={0}
                                        prefix={"$"}
                                        suffix={""}
                                        separator={","}
                                        end={responseData.activeVouchers ?? 0}
                                        decimals={2}
                                        duration={4}
                                    />
                                    </span>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Counter