import getApi from "apis/get.api";
import Bar from "Components/charts/Bar";
import { handleError } from "helpers/commonFunctions";
import { useEffect, useState } from "react";
import { Card, CardBody, Spinner } from "reactstrap";

interface Props {
    fetchChartOne : boolean;
    fetchChartTwo() : void;
}

const RedeemedVouchers = ({fetchChartOne,fetchChartTwo} : Props) => {
    const [vouchersCategories, setVouchersCategories] = useState<any>([]);
    const [vouchersValues, setVouchersValues] = useState<any>([]);
    const [selectedVoucherReport, setSelectedVouchersReport] = useState('1m');

    useEffect(() => {
        if(fetchChartOne) {
            getApi(`/admin/report/charts/one?groupBy=day`).then((response) => {
                setVouchersCategories(response.data.data.categories);
                setVouchersValues(response.data.data.values);
                fetchChartTwo();
            }).catch((error) => {
                handleError(error);
                fetchChartTwo();
            });   
        }
    },[fetchChartOne])
       
    const filterVoucherReport = (dateRange: string,groupBy = 'day') => {
        const endDate = new Date();
        let startDate = new Date();
    
        switch (dateRange) {
            case '1w': // 1 week
                startDate.setDate(endDate.getDate() - 7);
                break;
            case '1m': // 1 month
                startDate.setMonth(endDate.getMonth() - 1);
                break;
            case '6m': // 6 months
                startDate.setMonth(endDate.getMonth() - 6);
                break;
            case '1y': // 1 year
                startDate.setFullYear(endDate.getFullYear() - 1);
                break;
            default:
                return;
        }
    
        setSelectedVouchersReport(dateRange);
    
        // Format dates to yyyy-mm-dd
        const formatDate = (date: Date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };
    
        const formattedStartDate = formatDate(startDate);
        const formattedEndDate = formatDate(endDate);
    
        console.log(`Start Date: ${formattedStartDate}`);
        console.log(`End Date: ${formattedEndDate}`);
    
        // Add your API call here if needed
        getApi(`/admin/report/charts/one?startDate=${formattedStartDate}&endDate=${formattedEndDate}&groupBy=${groupBy}`).then((response) => {
            setVouchersCategories(response.data.data.categories);
            setVouchersValues(response.data.data.values);
        }).catch((error) => {
            handleError(error);
        });
    };

    return <Card>
    <div className="border-1 align-items-center d-flex card-header">
        <h4 className="card-title mb-0 flex-grow-1">My Redeemed Vouchers Chart</h4>
        {vouchersCategories.length > 1 && <div className="d-flex gap-1">
            <button type="button" onClick={() => {
                filterVoucherReport('1w', 'day');
            }}
                    className={`btn ${selectedVoucherReport === '1w' ? 'btn-primary' : 'btn-soft-secondary'} btn-sm shadow-none`}>1W
            </button>
            <button type="button" onClick={() => {
                filterVoucherReport('1m', 'day');
            }}
                    className={`btn ${selectedVoucherReport === '1m' ? 'btn-primary' : 'btn-soft-secondary'} btn-sm shadow-none`}>1M
            </button>
            <button type="button" onClick={() => {
                filterVoucherReport('6m', 'month');
            }}
                    className={`btn ${selectedVoucherReport === '6m' ? 'btn-primary' : 'btn-soft-secondary'} btn-sm shadow-none`}>6M
            </button>
            <button type="button" onClick={() => {
                filterVoucherReport('1y', 'month');
            }}
                    className={`btn ${selectedVoucherReport === '1y' ? 'btn-primary' : 'btn-soft-secondary'} btn-sm shadow-none`}>1Y
            </button>
        </div>}
    </div>
    <CardBody>
        {vouchersCategories.length == 0 && <div className='d-flex justify-content-center p-5'>
            <Spinner className=''/>
        </div>} 
        {vouchersCategories.length > 1 && <Bar 
            enableDataLabels={false} 
            distributed={false} 
            horizontal={false}  
            categories={vouchersCategories} 
            values={vouchersValues} 
            dataColors={null}
        />}
    </CardBody>
</Card>
}

export default RedeemedVouchers