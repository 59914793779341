import React, { useEffect, useState } from 'react';
import {
    Container,
    Spinner,
    Pagination,
    PaginationItem,
    PaginationLink, Col, Label, Input, Row,
} from "reactstrap";
import { toast } from "react-toastify";
import getApi from "../../apis/get.api";

const Resellers = () => {
    document.title = "Admin | Resellers List";
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [resellerEmail, setResellerEmail] = useState('');    
    const fetchResellers = (page : number, email : string) => {
        setLoading(true);
        let url = `/admin/reseller/list?page=${page}&per_page=20`;
        if (email) {
            url += `&email=${email}`;
        }
        getApi(url)
            .then((response) => {
                const data = response.data.data;
                setList(data.data);
                setCurrentPage(data.current_page);
                setLastPage(data.last_page);
            })
            .catch((error) => {
                toast.error('There was an issue while fetching the reseller list');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchResellers(currentPage, resellerEmail);
    }, [currentPage, resellerEmail]);

    const handlePageChange = (page : number) => {
        if (page !== currentPage) {
            setCurrentPage(page);
        }
    };

    const handleFilterChange = (e : any) => {
        setResellerEmail(e.target.value);
    };

    const handleFilterSubmit = (e : any) => {
        e.preventDefault();
        setCurrentPage(1);
        fetchResellers(1, resellerEmail);
    };

    const getStatusLabel = (status : string) => {
        let label = 'Approved';
        switch (status) {
            case 'b':
                label = 'Blocked';
                break;
            case 'p':
                label = 'Pending';
                break;
            case 'r':
                label = 'Rejected';
                break;
        }
        return label;
    }

    return (
        <div className="page-content">
            <Container fluid={true}>
                <div className="rounded-3">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header fs-4">
                                    In this page you will find all resellers list that are registered
                                </div>
                                <div className="card-body">
                                    <Row className=" align-items-center">
                                        <Col lg={4}>
                                            <Label className="visually-hidden" htmlFor="inlineFormInputGroupUsername">Username</Label>
                                            <div className="input-group">
                                                <div className="input-group-text">Reseller Email</div>
                                                <Input type="text" className="form-control" id="resellerEmail" value={resellerEmail} onChange={handleFilterChange} placeholder="Reseller Email" />
                                            </div>
                                        </Col>
                                        <Col xs={8} className={"text-end"}>
                                            <button type="submit" className="btn btn-primary" onClick={handleFilterSubmit}>Search</button>
                                        </Col>
                                    </Row>
                                    <div className="table-responsive table-card mt-4">
                                        <table className="table text-center align-middle table-nowrap table-striped-columns mb-0">
                                            <thead className="table-light">
                                            <tr>
                                                <th scope="col" className={"text-center"}>Reseller ID</th>
                                                <th scope="col">Reseller Name</th>
                                                <th scope="col">Reseller Email</th>
                                                <th scope="col">Reseller Country</th>
                                                <th scope="col">Reseller Phone Number</th>
                                                <th scope="col">Reseller Trader Name | Website</th>
                                                <th scope="col">Reseller Status</th>
                                                <th scope="col">Reseller Balance USD</th>
                                                <th scope="col">Reseller Balance MAD</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {loading && (
                                                <tr>
                                                    <td className="text-center p-4" colSpan={10}><Spinner /></td>
                                                </tr>
                                            )}
                                            {list && list.length > 0 && list.map((reseller : any) => (
                                                <tr key={reseller.resellerId} className={"fw-bold"}>
                                                    <td className={"text-center"}><span
                                                        className="fw-medium">#{reseller.resellerId}</span>
                                                    </td>
                                                    <td>{reseller.resellerUsername}</td>
                                                    <td>{reseller.resellerEmail}</td>
                                                    <td>{reseller.resellerCountryName}</td>
                                                    <td>{reseller.resellerPhoneNumber ?? 'Not Provided'}</td>
                                                    <td>{reseller.resellerWebsiteUrl ?? 'Not Provided'}</td>
                                                    <td>{getStatusLabel(reseller.resellerStatus)}</td>
                                                    <td>{reseller.resellerBalance ?? 'No Wallet'}</td>
                                                    <td>{reseller.resellerMadBalance ?? 'No Wallet'}</td>
                                                    <td>
                                                        <button className="btn btn-sm btn-primary"
                                                                onClick={() => window.location.href = '/reseller/list/edit/' + reseller.resellerId}>View
                                                            Reseller
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                            {!loading && list.length === 0 && (
                                                <tr>
                                                    <td className="text-center p-4" colSpan={10}>No Merchants Found</td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="card-footer d-flex justify-content-center mt-4 border-top-0">
                                        <Pagination>
                                            <PaginationItem disabled={currentPage === 1}>
                                                <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)} />
                                            </PaginationItem>
                                            {[...Array(lastPage)].map((_, i) => (
                                                <PaginationItem key={i + 1} active={currentPage === i + 1}>
                                                    <PaginationLink onClick={() => handlePageChange(i + 1)}>
                                                        {i + 1}
                                                    </PaginationLink>
                                                </PaginationItem>
                                            ))}
                                            <PaginationItem disabled={currentPage === lastPage}>
                                                <PaginationLink next onClick={() => handlePageChange(currentPage + 1)} />
                                            </PaginationItem>
                                        </Pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Resellers;