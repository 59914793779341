import getApi from "../../../../apis/get.api";
import {toast} from "react-toastify";
import React, {useEffect, useState} from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Pagination,
    PaginationItem,
    PaginationLink,
    Spinner
} from "reactstrap";
import {Settlement} from "../../../../Interfaces/Interface";

interface PageProps {
    merchantId: number | string
}
const Settlements = ({merchantId} : PageProps) => {
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [showInformation,setShowInformation] = useState<boolean>(false);
    const [selectedSettle,setSelectedSettle] = useState<Settlement>();

    useEffect(() => {
        if(merchantId){
            fetchResellers(currentPage)
        }
    },[merchantId])

    const fetchResellers = (page: number) => {
        setLoading(true);
        let url = `/admin/merchant/settle/list/${merchantId}?page=${page}&per_page=20`;
        getApi(url)
        .then((response) => {
            const data = response.data.data;
            setList(data.data);
            setCurrentPage(data.current_page);
            setLastPage(data.last_page);
        })
        .catch((error) => {
            toast.error('There was an issue while fetching the transactions list');
        })
        .finally(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        fetchResellers(currentPage);
    }, [currentPage]);

    const handlePageChange = (page : number) => {
        if (page !== currentPage) {
            setCurrentPage(page);
        }
    };

    const viewInformation = (settle : Settlement) => {
        setShowInformation(!showInformation)
        setSelectedSettle(settle)
    }

    function tog_center() {
        setShowInformation(!showInformation);
    }

    return <div className="card">
        <div className="card-body">
            <div className="table-responsive table-card">
                <table className="table text-center align-middle table-nowrap table-striped-columns mb-0">
                    <thead className="table-light">
                    <tr>
                        <th scope="col" className={"text-center"}>ID</th>
                        <th scope="col">Settlement Request ID</th>
                        <th scope="col">Settlement Amount</th>
                        <th scope="col">Settlement Currency</th>
                        <th scope="col">Balance Before Settle</th>
                        <th scope="col">Balance After Settle</th>
                        <th scope="col">Settlement Creation Date</th>
                        <th scope="col">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {loading && (
                        <tr>
                            <td className="text-center p-4" colSpan={8}><Spinner/></td>
                        </tr>
                    )}
                    {list && list.length > 0 && list.map((transaction: Settlement) => (
                        <tr key={transaction.settlementId} className={"fw-bold"}>
                            <td className={"text-center"}><span
                                className="fw-medium">{transaction.settlementId}</span>
                            </td>
                            <td>{transaction.settlementRequestId}</td>
                            <td>{transaction.settlementAmount}</td>
                            <td>{transaction.settlementCurrency}</td>
                            <td>{transaction.settlementAmountBefore}</td>
                            <td>{transaction.settlementAmountAfter}</td>
                            <td>{transaction.settlementCreationDate}</td>
                            <td>
                                <button className="btn btn-sm btn-primary"
                                        onClick={() => {viewInformation(transaction)}}>View Settle
                                </button>
                            </td>
                        </tr>
                    ))}
                    {!loading && list.length === 0 && (
                        <tr>
                            <td className="text-center p-4" colSpan={8}>No Transactions Found</td>
                        </tr>
                    )}
                    </tbody>
                </table>
                <div className="d-flex justify-content-center mt-3">
                    <Pagination>
                        <PaginationItem disabled={currentPage === 1}>
                            <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)}/>
                        </PaginationItem>
                        {[...Array(lastPage)].map((_, i) => (
                            <PaginationItem key={i + 1} active={currentPage === i + 1}>
                                <PaginationLink onClick={() => handlePageChange(i + 1)}>
                                    {i + 1}
                                </PaginationLink>
                            </PaginationItem>
                        ))}
                        <PaginationItem disabled={currentPage === lastPage}>
                            <PaginationLink next onClick={() => handlePageChange(currentPage + 1)}/>
                        </PaginationItem>
                    </Pagination>
                </div>
                {selectedSettle && <Modal isOpen={showInformation} size={'xl'} toggle={() => { tog_center(); }} centered>
                    <ModalHeader className="border-4 pb-3">
                        Voucher Information
                    </ModalHeader>
                    <ModalBody className="">
                        <div className="g-3 row">
                            <div className="col-lg-3">
                                <label className="form-label form-label">
                                    Settle ID
                                </label>
                                <input
                                    name="settlementId"
                                    id="settlementId"
                                    type="text"
                                    readOnly={true}
                                    className="form-control"
                                    aria-invalid="false"
                                    disabled={true}
                                    value={selectedSettle.settlementId}
                                />
                            </div>
                            <div className="col-lg-3">
                                <label className="form-label form-label">
                                    Settle Merchant ID
                                </label>
                                <input
                                    name="settlementMerchantId"
                                    id="settlementMerchantId"
                                    type="text"
                                    readOnly={true}
                                    className="form-control"
                                    aria-invalid="false"
                                    disabled={true}
                                    value={selectedSettle.settlementMerchantId}
                                />
                            </div>
                            <div className="col-lg-3">
                                <label htmlFor="voucherDeactivatedDate-field" className="form-label form-label">
                                    Merchant Email
                                </label>
                                <input
                                    name="settlementMerchantEmail"
                                    id="settlementMerchantEmail"
                                    type="text"
                                    readOnly={true}
                                    className="form-control"
                                    aria-invalid="false"
                                    disabled={true}
                                    value={selectedSettle.settlementMerchantEmail}
                                />
                            </div>
                            <div className="col-lg-3">
                                <label htmlFor="voucherDeactivatedDate-field" className="form-label form-label">
                                    Merchant Username
                                </label>
                                <input
                                    name="settlementMerchantUsername"
                                    id="settlementMerchantUsername"
                                    type="text"
                                    readOnly={true}
                                    className="form-control"
                                    aria-invalid="false"
                                    disabled={true}
                                    value={selectedSettle.settlementMerchantUsername}
                                />
                            </div>
                            <div className="col-lg-3">
                                <label className="form-label form-label">
                                    Merchant Wallet ID
                                </label>
                                <input
                                    name="settlementMerchantWalletId"
                                    id="settlementMerchantWalletId"
                                    type="text"
                                    readOnly={true}
                                    className="form-control"
                                    aria-invalid="false"
                                    disabled={true}
                                    value={selectedSettle.settlementMerchantWalletId}
                                />
                            </div>
                            <div className="col-lg-3">
                                <label className="form-label form-label">
                                    Settle Request ID
                                </label>
                                <input
                                    name="settlementRequestId"
                                    id="settlementRequestId"
                                    type="text"
                                    readOnly={true}
                                    className="form-control"
                                    aria-invalid="false"
                                    disabled={true}
                                    value={selectedSettle.settlementRequestId}
                                />
                            </div>
                            <div className="col-lg-3">
                                <label className="form-label form-label">
                                    Settle Amount
                                </label>
                                <input
                                    name="settlementAmount"
                                    id="settlementAmount"
                                    type="text"
                                    readOnly={true}
                                    className="form-control"
                                    aria-invalid="false"
                                    disabled={true}
                                    value={selectedSettle.settlementAmount}
                                />
                            </div>
                            <div className="col-lg-3">
                                <label className="form-label form-label">
                                    Balance Before Settle
                                </label>
                                <input
                                    name="settlementAmountBefore"
                                    id="settlementAmountBefore"
                                    type="text"
                                    readOnly={true}
                                    className="form-control"
                                    aria-invalid="false"
                                    disabled={true}
                                    value={selectedSettle.settlementAmountBefore}
                                />
                            </div>
                            <div className="col-lg-3">
                                <label htmlFor="voucherStatus-field" className="form-label form-label">
                                    Balance After Settle
                                </label>
                                <input
                                    name="settlementAmountAfter"
                                    id="settlementAmountAfter"
                                    type="text"
                                    readOnly={true}
                                    className="form-control"
                                    aria-invalid="false"
                                    disabled={true}
                                    value={selectedSettle.settlementAmountAfter}
                                />
                            </div>
                            <div className="col-lg-3">
                                <label htmlFor="voucherExpiryDate-field" className="form-label form-label">
                                    Settle Date
                                </label>
                                <input
                                    name="settlementCreationDate"
                                    id="settlementCreationDate"
                                    type="text"
                                    readOnly={true}
                                    className="form-control"
                                    aria-invalid="false"
                                    disabled={true}
                                    value={selectedSettle.settlementCreationDate}
                                />
                            </div>
                            <div className="col-lg-3">
                                <label htmlFor="voucherRedeemedDate-field" className="form-label form-label">
                                    Settle Method
                                </label>
                                <input
                                    name="settlementRequestMethod"
                                    id="settlementRequestMethod"
                                    type="text"
                                    readOnly={true}
                                    className="form-control"
                                    aria-invalid="false"
                                    disabled={true}
                                    value={selectedSettle.settlementRequestMethod}
                                />
                            </div>
                            <div className="col-lg-3">
                                <label htmlFor="voucherDeactivatedDate-field" className="form-label form-label">
                                    Settle Request Date
                                </label>
                                <input
                                    name="settlementRequestCreationDate"
                                    id="settlementRequestCreationDate"
                                    type="text"
                                    readOnly={true}
                                    className="form-control"
                                    aria-invalid="false"
                                    disabled={true}
                                    value={selectedSettle.settlementRequestCreationDate}
                                />
                            </div>
                            <div className="col-lg-3">
                                <label htmlFor="voucherDeactivatedDate-field" className="form-label form-label">
                                    Action Taken By Admin
                                </label>
                                <input
                                    name="voucherDeactivatedDate"
                                    id="voucherDeactivatedDate"
                                    type="text"
                                    readOnly={true}
                                    className="form-control"
                                    aria-invalid="false"
                                    disabled={true}
                                    value={selectedSettle.settlementAdminUsername}
                                />
                            </div>
                        </div>
                    </ModalBody>
                </Modal>}
            </div>
        </div>
    </div>
}

export default Settlements