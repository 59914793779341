import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner } from 'reactstrap';

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link, useLocation, useNavigate } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { loginUser, socialLogin, resetLoginFlag } from "../../slices/thunks";

import logoLight from "../../assets/images/logo-light.png";
import { createSelector } from 'reselect';
import ParticlesAuth from 'pages/AuthenticationInner/ParticlesAuth';
import postApi from 'apis/post.api';
import { ToastContainer, toast } from 'react-toastify';
import { closePopupFunction } from 'slices/loginPopup/thunk';

const LoginSubComp = (props : any) => {
    document.title = "Login";
    const navigate = useNavigate();
    const [userFormData,setUserFormData] = useState<any>({email : '',password : '',userType : 'a'})
    const [error,setError] = useState<string>('')
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [loader, setLoader] = useState<boolean>(false);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const redirectTo = searchParams.get('redirectTo');
    const dispatch = useDispatch()

    const changeInputHandler = (event : any) => {
        const updatedForm = { ...userFormData };
        updatedForm[event.target.name] = event.target.value;
        setUserFormData(updatedForm);
        validateFormInput(event);
    }

    const validateFormInput = (event : any) => {
        if(event.target.type == 'email'){
            if(event.target.value == ''){
                event.target.classList.add('is-invalid')
            }else{
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if(emailRegex.test(event.target.value)){
                    event.target.classList.remove('is-invalid')
                    event.target.classList.add('is-valid')
                }else{
                    event.target.classList.add('is-invalid')
                }
            }
        }else if(event.target.type == 'password' || event.target.type == 'text'){
            if(event.target.value == ''){
                event.target.classList.add('is-invalid')
            }else{
                event.target.classList.remove('is-invalid')
                event.target.classList.add('is-valid')
            }
        }
    }

    const loginUser = () => {
        setError('')
        setLoader(true)
        postApi('/admin/login',userFormData).then((response) => {
            if(response.data.data.user){
                setLoader(false)
                localStorage.setItem("admin_authUser", JSON.stringify(response.data.data.user));
                localStorage.setItem("admin_access_token", response.data.data.access_token);
                window.location.reload()
            }else{
                setLoader(false)
                setError(response.data.error)
            }
        }).catch((error) => {
            setLoader(false)
            if(error.response.status == 422){
                setError(error.response.data.message)
            }else{
                toast.error('There was an error while authenticating your user. Please try again')
            }
        })
    }
    
    const toSignUpPage = () => {
        dispatch(closePopupFunction());
        navigate('/register')
    }

    return (
        <React.Fragment>
            <div className="auth-page-content">
                <Container>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />
                    <Row className="justify-content-center">
                        <Col md={12} lg={126} xl={12}>
                            <Card className="">
                                <CardBody className="">
                                    <div className="">
                                        {error && error ? (<Alert color="danger"> {error} </Alert>) : null}
                                        <Form  onSubmit={(e) => { e.preventDefault();  return false; }}  action="#">
                                            <div className="mb-3">
                                                <Label htmlFor="email" className="form-label">Email</Label>
                                                <Input
                                                    name="email"
                                                    className="form-control"
                                                    placeholder="Enter email"
                                                    type="email"
                                                    onChange={changeInputHandler}
                                                    value={userFormData.email}
                                                />
                                                <FormFeedback type="invalid">Email must be a valid email address</FormFeedback>
                                            </div>
                                            <div className="mb-3">
                                                <div className="float-end">
                                                    <Link to="/forgot-password" className="text-muted">Forgot password?</Link>
                                                </div>
                                                <Label className="form-label" htmlFor="password-input">Password</Label>
                                                <div className="position-relative auth-pass-inputgroup mb-3">
                                                    <Input
                                                        name="password"
                                                        value={userFormData.password}
                                                        type={showPassword ? "text" : "password"}
                                                        className="form-control pe-5"
                                                        placeholder="Enter Password"
                                                        onChange={changeInputHandler}
                                                    />
                                                    <FormFeedback type="invalid">Password is required</FormFeedback>
                                                    <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted shadow-none" onClick={() => setShowPassword(!showPassword)} type="button" id="password-addon"><i className="ri-eye-fill align-middle"></i></button>
                                                </div>
                                            </div>
                                            <div className="mt-4">
                                                <Button color="success"
                                                    disabled={loader && true}
                                                    onClick={loginUser}
                                                    className="btn btn-success w-100" type="submit">
                                                    {loader && <Spinner size="sm" className='me-2'> Loading... </Spinner>}
                                                    Sign In
                                                </Button>
                                            </div>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(LoginSubComp);