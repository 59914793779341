import getApi from "../../../../apis/get.api";
import {toast} from "react-toastify";
import React, {useEffect, useState} from "react";
import {Pagination, PaginationItem, PaginationLink, Spinner} from "reactstrap";

interface PageProps {
    resellerEmail: string
}
const Transactions = ({resellerEmail} : PageProps) => {
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);

    const fetchResellers = (page: number, email: string) => {
        setLoading(true);
        let url = `/admin/transaction/list?page=${page}&per_page=20`;
        if (email) {
            url += `&email=${email}`;
        }
        getApi(url)
            .then((response) => {
                const data = response.data.data;
                setList(data.data);
                setCurrentPage(data.current_page);
                setLastPage(data.last_page);
            })
            .catch((error) => {
                toast.error('There was an issue while fetching the transactions list');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchResellers(currentPage, resellerEmail);
    }, [currentPage, resellerEmail]);

    const handlePageChange = (page : number) => {
        if (page !== currentPage) {
            setCurrentPage(page);
        }
    };

    const getTransactionType = (transaction : any) => {
        let type = 'GENERATE'
        if(transaction.userType === 3 && transaction.transactionType === 'fund'){
            type = 'REDEEM'
        }else if(transaction.userType === 3 && transaction.transactionType === 'deduct'){
            type = 'SETTLE'
        }else if(transaction.userType === 2 && transaction.transactionType === 'fund'){
            type = 'WALLET FUND'
        }
        return type
    }

    return <div className="table-responsive table-card">
        <table className="table text-center align-middle table-nowrap table-striped-columns mb-0">
            <thead className="table-light">
            <tr>
                <th scope="col" className={"text-center"}>Unique ID</th>
                <th scope="col">Transaction ID</th>
                <th scope="col">Reseller Name</th>
                <th scope="col">Transaction Type</th>
                <th scope="col">Amount</th>
                <th scope="col">Currency</th>
                <th scope="col">Amount Before</th>
                <th scope="col">Amount After</th>
                <th scope="col">Transaction Date</th>
            </tr>
            </thead>
            <tbody>
            {loading && (
                <tr>
                    <td className="text-center p-4" colSpan={9}><Spinner/></td>
                </tr>
            )}
            {list && list.length > 0 && list.map((transaction: any) => (
                <tr key={transaction.transactionUniqueId} className={"fw-bold"}>
                    <td className={"text-center"}><span
                        className="fw-medium">#{transaction.transactionUniqueId}</span>
                    </td>
                    <td>{transaction.transactionId}</td>
                    <td>{transaction.userName}</td>
                    <td>{getTransactionType(transaction)}</td>
                    <td>{transaction.transactionAmount}</td>
                    <td>{transaction.transactionCurrency}</td>
                    <td>{transaction.transactionAmountBefore}</td>
                    <td>{transaction.transactionAmountAfter}</td>
                    <td>{transaction.transactionDate}</td>
                </tr>
            ))}
            {!loading && list.length === 0 && (
                <tr>
                    <td className="text-center p-4" colSpan={9}>No Transactions Found</td>
                </tr>
            )}
            </tbody>
        </table>
        <div className="d-flex justify-content-center mt-3">
            <Pagination>
                <PaginationItem disabled={currentPage === 1}>
                    <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)}/>
                </PaginationItem>
                {[...Array(lastPage)].map((_, i) => (
                    <PaginationItem key={i + 1} active={currentPage === i + 1}>
                        <PaginationLink onClick={() => handlePageChange(i + 1)}>
                            {i + 1}
                        </PaginationLink>
                    </PaginationItem>
                ))}
                <PaginationItem disabled={currentPage === lastPage}>
                    <PaginationLink next onClick={() => handlePageChange(currentPage + 1)}/>
                </PaginationItem>
            </Pagination>
        </div>

    </div>

}

export default Transactions