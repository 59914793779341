import getApi from "../../../../apis/get.api";
import {toast} from "react-toastify";
import React, {useEffect, useState} from "react";
import {
    Button,
    ButtonGroup,
    Col, DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    Label, Modal, ModalBody, Pagination, PaginationItem, PaginationLink,
    Row,
    Spinner,
    UncontrolledButtonDropdown
} from "reactstrap";
import Loader from "../../../../Components/Common/Loader";
import putApi from "../../../../apis/put.api";

interface PageProps {
    merchantId : number | string
}
const Vouchers = ({merchantId} : PageProps) => {
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [voucherCode, setVoucherCode] = useState('');
    const [vouchers, setVouchers] = useState([]);
    const [showRedeemModal, setShowRedeemModal] = useState(false);
    const [selectedVoucher, setSelectedVoucher] = useState(null);
    const [voucherActionLoader, setVoucherActionLoader] = useState(false);
    const [showDeactivateModal, setShowDeactivateModal] = useState(false);

    const fetchVouchers = (page : number, code : string) => {
        setLoading(true);
        let url = `/admin/merchant/voucher/list/${merchantId}?page=${page}&per_page=20`;
        if (code) {
            url += `&code=${code}`;
        }
        getApi(url)
            .then((response) => {
                const data = response.data.data;
                setVouchers(data.data);
                setCurrentPage(data.current_page);
                setLastPage(data.last_page);
            })
            .catch((error) => {
                toast.error('There was an issue while fetching the vouchers list');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchVouchers(currentPage, voucherCode);
    }, [currentPage, voucherCode]);

    const handleFilterChange = (e : any) => {
        setVoucherCode(e.target.value);
    };

    const handleFilterSubmit = (e : any) => {
        e.preventDefault();
        setCurrentPage(1);
        fetchVouchers(1, voucherCode);
    };

    const selectRedeem = (voucher : any) => {
        setSelectedVoucher(voucher);
        setShowRedeemModal(!showRedeemModal);
    }

    const selectDeactivate = (voucher : any) => {
        setSelectedVoucher(voucher);
        setShowDeactivateModal(!showRedeemModal);
    }

    const handlePageChange = (page : number) => {
        if (page !== currentPage) {
            setCurrentPage(page);
        }
    };

    function handleShowRedeemPopup() {
        setShowRedeemModal(!showRedeemModal);
    }

    function handleShowDeactivatePopup() {
        setShowDeactivateModal(!showDeactivateModal);
    }

    const deactivate = () => {
        setVoucherActionLoader(true)
        putApi('/admin/voucher/deactivate',selectedVoucher)
            .then((response) => {
                if(response.data.code === 10000){
                    toast.success('Voucher has been deactivated');
                    setShowDeactivateModal(!showDeactivateModal);
                    setVoucherActionLoader(false)
                    fetchVouchers(currentPage,voucherCode);
                }else{
                    setVoucherActionLoader(false)
                    toast.error('There was an issue while fetching the vouchers list');
                }
            })
            .catch((error) => {
                setVoucherActionLoader(false)
                toast.error('There was an issue while fetching the vouchers list');
            })
    }

    const redeem = () => {
        setVoucherActionLoader(true)
        putApi('/admin/voucher/redeem',selectedVoucher)
            .then((response) => {
                if(response.data.code === 10000){
                    toast.success('Voucher has been redeemed');
                    setShowRedeemModal(!showRedeemModal);
                    setVoucherActionLoader(false)
                    fetchVouchers(currentPage,voucherCode);
                }else{
                    setVoucherActionLoader(false)
                    toast.error('There was an issue while fetching the vouchers list');
                }
            })
            .catch((error) => {
                setVoucherActionLoader(false)
                toast.error('There was an issue while fetching the vouchers list');
            })
    }

    return <div className="card">
        <div className="card-header">
            <Row className=" align-items-center">
                <Col lg={4}>
                    <Label className="visually-hidden"
                        htmlFor="inlineFormInputGroupUsername">Username</Label>
                    <div className="input-group">
                        <div className="input-group-text">Voucher Code</div>
                        <Input type="text" className="form-control" id="voucherCode"
                            value={voucherCode} onChange={handleFilterChange}
                            placeholder="Voucher Code"/>
                    </div>
                </Col>
                <Col xs={8} className={"text-end"}>
                    <button type="submit" className="btn btn-primary"
                            onClick={handleFilterSubmit}>Search
                    </button>
                </Col>
            </Row>
        </div>
        <div className="card-body">
            <div className="table-responsive table-card">
                <table className="table align-middle table-nowrap table-striped-columns mb-0">
                    <thead className="table-light">
                    <tr className={"text-center"}>
                        <th scope="col" className={"text-start"}>Voucher ID</th>
                        <th scope="col">Reseller</th>
                        <th scope="col">Redeemed Merchant</th>
                        <th scope="col">Voucher Code</th>
                        <th scope="col">Voucher Redeem Date</th>
                        <th scope="col">Voucher Amount</th>
                        <th scope="col">Voucher Currency</th>
                        <th scope="col">Voucher Status</th>
                        <th scope="col">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {loading && (
                        <tr>
                            <td className="text-center p-4" colSpan={10}><Spinner/></td>
                        </tr>
                    )}
                    {!loading && vouchers.length > 0 && vouchers.map((voucher: any) => (
                        <tr key={voucher.voucherId} className={"text-center fw-bold"}>
                            <td className={"text-start"}><span
                                className="fw-medium">#{voucher.voucherId}</span></td>
                            <td>{voucher.resellerName??'Payout'}</td>
                            <td>{voucher.redeemingMerchantName ?? 'Not Redeemed'}</td>
                            <td>{voucher.voucherCode}</td>
                            <td>{voucher.voucherRedeemedDate ?? 'Not Redeemed'}</td>
                            <td>{voucher.voucherAmount}</td>
                            <td>{voucher.voucherCurrency}</td>
                            <td>{voucher.voucherStatus.toString().toUpperCase()}</td>
                            <td>
                                {voucher.voucherStatus === 'active' && <ButtonGroup>
                                    <UncontrolledButtonDropdown id="btnGroupDrop1">
                                        <DropdownToggle color="primary" caret size={'sm'}>
                                            Actions
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={() => {
                                                selectRedeem(voucher)
                                            }}> Redeem</DropdownItem>
                                            <DropdownItem onClick={() => {
                                                selectDeactivate(voucher)
                                            }}> Deactivate </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </ButtonGroup>}
                                {(voucher.voucherStatus === 'redeemed' || voucher.voucherStatus === 'deactivated' || voucher.voucherStatus === 'expired') && <>No
                                    Action</>}
                            </td>
                        </tr>
                    ))}
                    {!loading && vouchers.length === 0 && (
                        <tr>
                            <td className="text-center p-4" colSpan={10}>No Vouchers Found</td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
            <div className="card-footer d-flex justify-content-center mt-4 border-top-0">
                <Pagination>
                    {/* Previous Button */}
                    <PaginationItem disabled={currentPage === 1}>
                        <PaginationLink previous
                                        onClick={() => handlePageChange(currentPage - 1)}/>
                    </PaginationItem>

                    {/* Pagination Numbers */}
                    {[...Array(lastPage)].map((_, i) => {
                        // Display first two pages, last two pages, and pages near the current page
                        const pageNumber = i + 1;
                        const isNearCurrentPage = Math.abs(currentPage - pageNumber) <= 2;
                        const isFirstOrLastPage = pageNumber === 1 || pageNumber === lastPage;

                        if (isNearCurrentPage || isFirstOrLastPage) {
                            return (
                                <PaginationItem key={pageNumber}
                                                active={currentPage === pageNumber}>
                                    <PaginationLink
                                        onClick={() => handlePageChange(pageNumber)}>
                                        {pageNumber}
                                    </PaginationLink>
                                </PaginationItem>
                            );
                        }
                        // Ellipsis for skipped pages
                        if (pageNumber === 2 && currentPage > 4) {
                            return <PaginationItem key="ellipsis1"
                                                disabled><PaginationLink>...</PaginationLink></PaginationItem>;
                        }
                        if (pageNumber === lastPage - 1 && currentPage < lastPage - 3) {
                            return <PaginationItem key="ellipsis2"
                                                disabled><PaginationLink>...</PaginationLink></PaginationItem>;
                        }

                        return null; // Don't render other page numbers
                    })}

                    {/* Next Button */}
                    <PaginationItem disabled={currentPage === lastPage}>
                        <PaginationLink next onClick={() => handlePageChange(currentPage + 1)}/>
                    </PaginationItem>
                </Pagination>
                <Modal isOpen={showRedeemModal} toggle={handleShowRedeemPopup} centered>
                    <ModalBody className="text-center ">
                        {!voucherActionLoader && <div className="mt-4">
                            <h4 className="mb-3">Are you sure?</h4>
                            <p className="text-muted mb-4">Are you sure you want to redeem this voucher?</p>
                            <div className="hstack gap-2 justify-content-center">
                                <Button to="#" className="btn btn-danger fw-medium" onClick={handleShowRedeemPopup}><i
                                    className="ri-close-line me-1 align-middle"></i> Close</Button>
                                <Button to="#" className="btn btn-primary" onClick={redeem}>Redeem</Button>
                            </div>
                        </div>}
                        {voucherActionLoader && <Loader/>}
                    </ModalBody>
                </Modal>
                <Modal isOpen={showDeactivateModal} toggle={handleShowDeactivatePopup} centered>
                    <ModalBody className="text-center ">
                        {!voucherActionLoader && <div className="mt-4">
                            <h4 className="mb-3">Are you sure?</h4>
                            <p className="text-muted mb-4">Are you sure you want to deactivate this voucher?</p>
                            <div className="hstack gap-2 justify-content-center">
                                <Button to="#" className="btn btn-danger fw-medium" onClick={handleShowDeactivatePopup}><i className="ri-close-line me-1 align-middle"></i> Close</Button>
                                <Button to="#" className="btn btn-primary" onClick={deactivate}>Deactivate</Button>
                            </div>
                        </div>}
                        {voucherActionLoader && <Loader/>}
                    </ModalBody>
                </Modal>
            </div>
        </div>
    </div>
}

export default Vouchers