import { getSystemCurrency } from "helpers/commonFunctions";
import ReactApexChart from "react-apexcharts";

const getChartColorsArray = (colors : any) => {
    colors = JSON.parse(colors);
    return colors.map(function (value : any) {
        var newValue = value.replace(" ", "");
        if (newValue.indexOf(",") === -1) {
            var color = getComputedStyle(document.documentElement).getPropertyValue(newValue);

            if (color.indexOf("#") !== -1)
                color = color.replace(" ", "");
            if (color) return color;
            else return newValue;
        } else {
            var val = value.split(',');
            if (val.length === 2) {
                var rgbaColor = getComputedStyle(document.documentElement).getPropertyValue(val[0]);
                rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
                return rgbaColor;
            } else {
                return newValue;
            }
        }
    });
}

const TreeMap = ({values,dataColors} :any) => {
    const series = [{
        data: values
    }];
     
    var options : any = {
        legend: {
            show: false
        },
        chart: {
            height: 350,
            type: 'treemap',
            toolbar: {
                show: false
            }
        },
    };

    return <ReactApexChart dir="ltr"
        options={options}
        series={series}
        type="treemap"
        height="350"
        className="apex-charts"
    />
};

export default TreeMap