import React, {useEffect, useState} from 'react';
import {Card, CardBody, Col, Container, Row, Spinner,} from 'reactstrap';

import Section1 from "../../project-pages/dashboard/Section1";
import CounterWidget from "../../project-pages/dashboard/CounterWidget";
import getApi from "../../apis/get.api";
import {toast} from "react-toastify";
import { handleError } from 'helpers/commonFunctions';
import Bar from 'Components/charts/Bar';
import RedeemedVouchers from './charts/RedeemedVouchers';
import MerchantsCountriesChart from './charts/MerchantsCountriesChart';
import ResellersCountriesChart from './charts/ResellersCountriesChart';
import GeneralChart from './charts/GeneralChart';
import CountUp from 'react-countup';

const Dashboard = (props : any) => {
    document.title = "ClvrPay Admin | Dashboard";
    const [fetchChartOne,setFetchChartOne] = useState<boolean>(false)
    const [shouldFetchChartTow,setShouldFetchChartTow] = useState<boolean>(false)
    const [shouldFetchChartThree,setShouldFetchChartThree] = useState<boolean>(false)
    const [report,setReport] = useState<any>([])

    useEffect(() => {
        getApi('/admin/dashboard').then((response) => {
            setReport(response.data.data)
            setFetchChartOne(true)
        }).catch((error) => {
            handleError(error);
            setFetchChartOne(true)
        })
    }, []);

    const fetchChartTwo = () => {
        setShouldFetchChartTow(true)
    }

    const fetchChartThree = () => {
        setShouldFetchChartThree(true)
    }

    return <div className="page-content">
        <Container fluid={true}>
            <Row>
                <Col lg={12}>
                    {!report && <div className={"p-5 text-center"}><Spinner/></div>}
                    {report && <div className='row'>
                        <div className="col-xl-12">
                            <div className="card crm-widget">
                                <div className="card-body p-0">
                                    <div className="row row-cols-xxl-5 row-cols-md-3 row-cols-1 g-0">
                                        <div className="col">
                                            <div className="py-4 px-3">
                                                <h5 className="text-muted text-uppercase fs-13">Active Vouchers<i
                                                    className={" fs-18 float-end align-middle"}></i></h5>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0">
                                                        <i className={"display-6 text-muted"}></i>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h2 className="mb-0">
                                                            <span className="counter-value" data-target="197">
                                                            <CountUp
                                                                start={0}
                                                                prefix={"$"}
                                                                suffix={""}
                                                                separator={","}
                                                                end={report.activeSum ?? 0}
                                                                decimals={2}
                                                                duration={2}
                                                            />
                                                            </span>
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="py-4 px-3 ms-3">
                                                <h5 className="text-muted text-uppercase fs-13">Redeemed Vouchers<i
                                                    className={" fs-18 float-end align-middle"}></i></h5>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0">
                                                        <i className={"display-6 text-muted"}></i>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h2 className="mb-0">
                                                            <span className="counter-value" data-target="197">
                                                            <CountUp
                                                                start={0}
                                                                prefix={"$"}
                                                                suffix={""}
                                                                separator={","}
                                                                end={report.redeemedSum ?? 0}
                                                                decimals={2}
                                                                duration={4}
                                                            />
                                                            </span>
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="py-4 px-3 ms-3">
                                                <h5 className="text-muted text-uppercase fs-13">Expired Vouchers<i
                                                    className={" fs-18 float-end align-middle"}></i></h5>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0">
                                                        <i className={"display-6 text-muted"}></i>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h2 className="mb-0">
                                                            <span className="counter-value" data-target="197">
                                                            <CountUp
                                                                start={0}
                                                                prefix={"$"}
                                                                suffix={""}
                                                                separator={","}
                                                                end={report.expiredSum ?? 0}
                                                                decimals={2}
                                                                duration={4}
                                                            />
                                                            </span>
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="py-4 px-3 ms-3">
                                                <h5 className="text-muted text-uppercase fs-13">Deactivated Vouchers<i
                                                    className={" fs-18 float-end align-middle"}></i></h5>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0">
                                                        <i className={"display-6 text-muted"}></i>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h2 className="mb-0">
                                                            <span className="counter-value" data-target="197">
                                                            <CountUp
                                                                start={0}
                                                                prefix={"$"}
                                                                suffix={""}
                                                                separator={","}
                                                                end={report.deactivatedSum ?? 0}
                                                                decimals={2}
                                                                duration={4}
                                                            />
                                                            </span>
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="py-4 px-3 ms-3">
                                                <h5 className="text-muted text-uppercase fs-13">Total Pre-Loaded Balances<i
                                                    className={" fs-18 float-end align-middle"}></i></h5>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0">
                                                        <i className={"display-6 text-muted"}></i>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h2 className="mb-0">
                                                            <span className="counter-value" data-target="197">
                                                            <CountUp
                                                                start={0}
                                                                prefix={"$"}
                                                                suffix={""}
                                                                separator={","}
                                                                end={report.merchantPreLoadedBalances ?? 0}
                                                                decimals={2}
                                                                duration={4}
                                                            />
                                                            </span>
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                </Col>
                <Col lg={12}>
                    <RedeemedVouchers fetchChartTwo={fetchChartTwo} fetchChartOne={fetchChartOne}/>
                </Col>
                <Col lg={6}>
                    <MerchantsCountriesChart fetchChartTwo={shouldFetchChartTow} fetchChartThree={fetchChartThree}/>
                </Col>
                <Col lg={6}>
                    <ResellersCountriesChart fetchChartThree={shouldFetchChartThree}/>
                </Col>
            </Row>
        </Container>
    </div>
};

export default Dashboard;