import Login from "pages/Authentication/Login";
import Logout from "pages/Authentication/Logout";
import Basic404 from "pages/AuthenticationInner/Errors/Basic404";
import CreateVoucher from "../project-pages/vouchers/create/CreateVoucher";
import ListVoucher from "../project-pages/vouchers/list/ListVoucher";
import Dashboard from "../project-pages/dashboard/Dashboard";
import Register from "../pages/Authentication/Register";
import Merchants from "../project-pages/list/Merchants";
import Resellers from "../project-pages/list/Resellers";
import Transactions from "../project-pages/list/Transactions";
import FundWallet from "../project-pages/wallet/FundWallet";
import EditPage from "project-pages/list/EditPage/EditPage";
import Settlements from "project-pages/list/Settlements";
import EditMerchantPage from "../project-pages/list/EditMerchant/EditMerchantPage";

const authProtectedRoutes = [
  { path: "/",component: <Dashboard/>},
  { path: "/merchant/list",component: <Merchants/>},
  { path: "/reseller/list",component: <Resellers/>},  
  { path: "/reseller/list/edit/:id",component: <EditPage/>},
  { path: "/merchant/list/edit/:id",component: <EditMerchantPage/>},
  { path: "/voucher/list",component: <ListVoucher/>},
  { path: "/transaction/list",component: <Transactions/>},
  { path: "/voucher/create",component: <CreateVoucher/>},
  { path: "/reseller/wallet/fund",component: <FundWallet/>},
  { path: "/settlements/list",component: <Settlements/>},
  { path :"*",component: <Basic404/>},
];

const publicRoutes = [
  { path: "/login",component: <Login/>},
  { path: "/register",component: <Register/>},
  { path: "/logout",component: <Logout/>},
  {path :"*",component: <Basic404/>},
];

export { authProtectedRoutes, publicRoutes };