import React, { useEffect, useState } from 'react';
import {
    Container,
    Spinner,
    Pagination,
    PaginationItem,
    PaginationLink,
    Input,
    Button,
    Form,
    FormGroup,
    Label,
    Col,
    Row,
    ButtonGroup,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody
} from "reactstrap";
import getApi from "../../../apis/get.api";
import { toast } from "react-toastify";
import {Link} from "react-router-dom";
import putApi from "../../../apis/put.api";
import Loader from "../../../Components/Common/Loader";

const ListVoucher = () => {
    document.title = "Voucher | List Vouchers";
    const [vouchers, setVouchers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [voucherCode, setVoucherCode] = useState('');
    const [showRedeemModal, setShowRedeemModal] = useState(false);
    const [selectedVoucher, setSelectedVoucher] = useState(null);
    const [voucherActionLoader, setVoucherActionLoader] = useState(false);
    const [showDeactivateModal, setShowDeactivateModal] = useState(false);

    const fetchVouchers = (page : number, code : string) => {
        setLoading(true);
        let url = `/admin/voucher/list?page=${page}&per_page=20`;
        if (code) {
            url += `&code=${code}`;
        }
        getApi(url)
        .then((response) => {
            const data = response.data.data;
            setVouchers(data.data);
            setCurrentPage(data.current_page);
            setLastPage(data.last_page);
        })
        .catch((error) => {
            toast.error('There was an issue while fetching the vouchers list');
        })
        .finally(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        fetchVouchers(currentPage, voucherCode);
    }, [currentPage, voucherCode]);

    const handlePageChange = (page : number) => {
        if (page !== currentPage) {
            setCurrentPage(page);
        }
    };

    const handleFilterChange = (e : any) => {
        setVoucherCode(e.target.value);
    };

    const handleFilterSubmit = (e : any) => {
        e.preventDefault();
        setCurrentPage(1);
        fetchVouchers(1, voucherCode);
    };

    function handleShowRedeemPopup() {
        setShowRedeemModal(!showRedeemModal);
    }

    const redeem = () => {
        setVoucherActionLoader(true)
        putApi('/admin/voucher/redeem',selectedVoucher)
        .then((response) => {
            if(response.data.code === 10000){
                toast.success('Voucher has been redeemed');
                setShowRedeemModal(!showRedeemModal);
                setVoucherActionLoader(false)
                fetchVouchers(currentPage,voucherCode);
            }else{
                setVoucherActionLoader(false)
                toast.error('There was an issue while fetching the vouchers list');
            }
        })
        .catch((error) => {
            setVoucherActionLoader(false)
            toast.error('There was an issue while fetching the vouchers list');
        })
    }

    function handleShowDeactivatePopup() {
        setShowDeactivateModal(!showDeactivateModal);
    }

    const deactivate = () => {
        setVoucherActionLoader(true)
        putApi('/admin/voucher/deactivate',selectedVoucher)
            .then((response) => {
                if(response.data.code === 10000){
                    toast.success('Voucher has been deactivated');
                    setShowDeactivateModal(!showDeactivateModal);
                    setVoucherActionLoader(false)
                    fetchVouchers(currentPage,voucherCode);
                }else{
                    setVoucherActionLoader(false)
                    toast.error('There was an issue while fetching the vouchers list');
                }
            })
            .catch((error) => {
                setVoucherActionLoader(false)
                toast.error('There was an issue while fetching the vouchers list');
            })
    }

    const selectRedeem = (voucher : any) => {
        setSelectedVoucher(voucher);
        setShowRedeemModal(!showRedeemModal);
    }

    const selectDeactivate = (voucher : any) => {
        setSelectedVoucher(voucher);
        setShowDeactivateModal(!showRedeemModal);
    }

    return (
        <div className="page-content">
            <Container fluid={true}>
                <div className="rounded-3">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header fs-4">
                                    In this page you will find all generated vouchers for all resellers, along with the status of each voucher
                                </div>
                                <div className="card-body">
                                    <Row className=" align-items-center">
                                        <Col lg={4}>
                                            <Label className="visually-hidden"
                                                   htmlFor="inlineFormInputGroupUsername">Username</Label>
                                            <div className="input-group">
                                                <div className="input-group-text">Voucher Code</div>
                                                <Input type="text" className="form-control" id="voucherCode"
                                                       value={voucherCode} onChange={handleFilterChange}
                                                       placeholder="Voucher Code"/>
                                            </div>
                                        </Col>
                                        <Col xs={8} className={"text-end"}>
                                            <button type="submit" className="btn btn-primary"
                                                    onClick={handleFilterSubmit}>Search
                                            </button>
                                        </Col>
                                    </Row>
                                    <div className="table-responsive table-card mt-4 fw-bold">
                                        <table className="table align-middle table-nowrap table-striped-columns mb-0">
                                            <thead className="table-light">
                                            <tr className={"text-center"}>
                                                <th scope="col" className={"text-start"}>Voucher ID</th>
                                                <th scope="col">Reseller</th>
                                                <th scope="col">Merchant</th>
                                                <th scope="col">Redeemed Merchant</th>
                                                <th scope="col">Voucher Code</th>
                                                <th scope="col">Voucher Expiration</th>
                                                <th scope="col">Voucher Amount</th>
                                                <th scope="col">Voucher Currency</th>
                                                <th scope="col">Voucher Status</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {loading && (
                                                <tr>
                                                    <td className="text-center p-4" colSpan={10}><Spinner/></td>
                                                </tr>
                                            )}
                                            {!loading && vouchers.length > 0 && vouchers.map((voucher: any) => (
                                                <tr key={voucher.voucherId} className={"text-center"}>
                                                    <td className={"text-start"}><span
                                                        className="fw-medium">#{voucher.voucherId}</span></td>
                                                    <td>{voucher.resellerName ?? 'Payout'}</td>
                                                    <td>{voucher.generatingMerchantName}</td>
                                                    <td>{voucher.redeemingMerchantName ?? 'Not Redeemed'}</td>
                                                    <td>{voucher.voucherCode}</td>
                                                    <td>{voucher.voucherExpiryDate}</td>
                                                    <td>{voucher.voucherAmount}</td>
                                                    <td>{voucher.voucherCurrency.toString().toUpperCase()}</td>
                                                    <td>{voucher.voucherStatus.toString().toUpperCase()}</td>
                                                    <td>
                                                        {voucher.voucherStatus === 'active' && <ButtonGroup>
                                                            <UncontrolledButtonDropdown id="btnGroupDrop1">
                                                                <DropdownToggle color="primary" caret size={'sm'}>
                                                                    Actions
                                                                </DropdownToggle>
                                                                <DropdownMenu>
                                                                    <DropdownItem onClick={() => {
                                                                        selectRedeem(voucher)
                                                                    }}> Redeem</DropdownItem>
                                                                    <DropdownItem onClick={() => {
                                                                        selectDeactivate(voucher)
                                                                    }}> Deactivate </DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledButtonDropdown>
                                                        </ButtonGroup>}
                                                        {(voucher.voucherStatus === 'redeemed' || voucher.voucherStatus === 'deactivated' || voucher.voucherStatus === 'expired') && <>No
                                                            Action</>}
                                                    </td>
                                                </tr>
                                            ))}
                                            {!loading && vouchers.length === 0 && (
                                                <tr>
                                                    <td className="text-center p-4" colSpan={10}>No Vouchers Found</td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="card-footer d-flex justify-content-center mt-4 border-top-0">
                                        <Pagination>
                                            {/* Previous Button */}
                                            <PaginationItem disabled={currentPage === 1}>
                                                <PaginationLink previous
                                                                onClick={() => handlePageChange(currentPage - 1)}/>
                                            </PaginationItem>

                                            {/* Pagination Numbers */}
                                            {[...Array(lastPage)].map((_, i) => {
                                                // Display first two pages, last two pages, and pages near the current page
                                                const pageNumber = i + 1;
                                                const isNearCurrentPage = Math.abs(currentPage - pageNumber) <= 2;
                                                const isFirstOrLastPage = pageNumber === 1 || pageNumber === lastPage;

                                                if (isNearCurrentPage || isFirstOrLastPage) {
                                                    return (
                                                        <PaginationItem key={pageNumber}
                                                                        active={currentPage === pageNumber}>
                                                            <PaginationLink
                                                                onClick={() => handlePageChange(pageNumber)}>
                                                                {pageNumber}
                                                            </PaginationLink>
                                                        </PaginationItem>
                                                    );
                                                }
                                                // Ellipsis for skipped pages
                                                if (pageNumber === 2 && currentPage > 4) {
                                                    return <PaginationItem key="ellipsis1"
                                                                           disabled><PaginationLink>...</PaginationLink></PaginationItem>;
                                                }
                                                if (pageNumber === lastPage - 1 && currentPage < lastPage - 3) {
                                                    return <PaginationItem key="ellipsis2"
                                                                           disabled><PaginationLink>...</PaginationLink></PaginationItem>;
                                                }

                                                return null; // Don't render other page numbers
                                            })}

                                            {/* Next Button */}
                                            <PaginationItem disabled={currentPage === lastPage}>
                                                <PaginationLink next onClick={() => handlePageChange(currentPage + 1)}/>
                                            </PaginationItem>
                                        </Pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <Modal isOpen={showRedeemModal} toggle={handleShowRedeemPopup} centered>
                <ModalBody className="text-center ">
                    {!voucherActionLoader && <div className="mt-4">
                        <h4 className="mb-3">Are you sure?</h4>
                        <p className="text-muted mb-4">Are you sure you want to redeem this voucher?</p>
                        <div className="hstack gap-2 justify-content-center">
                            <Button to="#" className="btn btn-danger fw-medium" onClick={handleShowRedeemPopup}><i
                                className="ri-close-line me-1 align-middle"></i> Close</Button>
                            <Button to="#" className="btn btn-primary" onClick={redeem}>Redeem</Button>
                        </div>
                    </div>}
                    {voucherActionLoader && <Loader/>}
                </ModalBody>
            </Modal>
            <Modal isOpen={showDeactivateModal} toggle={handleShowDeactivatePopup} centered>
                <ModalBody className="text-center ">
                    {!voucherActionLoader && <div className="mt-4">
                        <h4 className="mb-3">Are you sure?</h4>
                        <p className="text-muted mb-4">Are you sure you want to deactivate this voucher?</p>
                        <div className="hstack gap-2 justify-content-center">
                            <Button to="#" className="btn btn-danger fw-medium" onClick={handleShowDeactivatePopup}><i className="ri-close-line me-1 align-middle"></i> Close</Button>
                            <Button to="#" className="btn btn-primary" onClick={deactivate}>Deactivate</Button>
                        </div>
                    </div>}
                    {voucherActionLoader && <Loader/>}
                </ModalBody>
            </Modal>
        </div>
    );
};

export default ListVoucher;