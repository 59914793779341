import getApi from "apis/get.api"
import Mixed from "Components/charts/Mixed"
import { handleError } from "helpers/commonFunctions"
import { useEffect, useState } from "react"
import { Card, CardBody, Spinner } from "reactstrap"

const VoucherTotalReport = ({forceFetch,id,fetchChartOne} : any) => {
    const [vouchersReportCategories, setVouchersReportCategories] = useState<any>(null);
    const [vouchersReportValues, setVouchersReportValues] = useState<any>(null);

    useEffect(() => {
        getApi(`/merchant/report/chart/vouchers-report/${id}`).then((response) => {
            setVouchersReportCategories(response.data.data.categories);
            setVouchersReportValues(response.data.data.values);
        }).catch((error) => {
            handleError(error);
        });
    },[])
    
    return <Card className="">
        <div className="border-1 align-items-center d-flex card-header">
            <h4 className="card-title mb-0 flex-grow-1">Vouchers Totals Report</h4>
            <div className="d-flex gap-1">
                <button type="button" disabled
                        className={`btn btn-secondary btn-sm shadow-none`}>No Filter
                </button>
            </div>
        </div>
        <CardBody className={!vouchersReportCategories ? 'force-center-div h-350px' : ''}>
            {!vouchersReportCategories  && <div className="">
                <Spinner/>
            </div>}
            {vouchersReportCategories && vouchersReportCategories.length > 0 && 
            <Mixed enableDataLabels={true} distributed={true} horizontal={true}
                categories={vouchersReportCategories} values={vouchersReportValues}
                dataColors='["--vz-primary", "--vz-secondary", "--vz-success", "--vz-info", "--vz-warning", "--vz-danger", "--vz-dark", "--vz-primary", "--vz-success", "--vz-secondary"]'/>}
        </CardBody>
    </Card>
}

export default VoucherTotalReport