import React, {useEffect, useState} from 'react';
import {
    Container,
    Input,
    Label,
    Modal,
    Spinner,
    ModalHeader,
    ModalBody,
    Card,
    CardBody,
    Nav,
    NavItem, NavLink, TabContent, TabPane, CardHeader,
    ButtonGroup,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledButtonDropdown
} from "reactstrap";
import Select from "react-select";
import getApi from "../../apis/get.api";
import postApi from "../../apis/post.api";
import {toast} from "react-toastify";
import classnames from "classnames";

const FundWallet = () => {
    document.title = "Voucher | Fund Reseller And Merchant Wallet";
    const [resellers,setResellers] = useState(null)
    const [merchants,setMerchants] = useState(null)
    const [transactions,setTransactions] = useState([])
    const [disabledFundButton,setDisabledFundButton] = useState(false)
    const [sourceTransactionId,setSourceTransactionId] = useState<any>('')
    const [reseller,setReseller] = useState(null)
    const [merchant,setMerchant] = useState(null)
    const [amount,setAmount] = useState<any>(null)
    const [showFundModel, setShowFundModel] = useState<boolean>(false);
    const [loading,setLoading] = useState(false)
    const [activeTab, setactiveTab] = useState("1");
    const [merchantAmount,setMerchantAmount] = useState<any>(null)
    const [merchantSourceTransactionId,setMerchantSourceTransactionId] = useState<any>('')
    const [disabledMerchantFundButton,setDisabledMerchantFundButton] = useState(false)
    const [currency,setCurrency] = useState<any>('USD')

    const toggle = (tab : any) => {
        if (activeTab !== tab) {
            setactiveTab(tab);
        }
    };
    useEffect(() => {
        fetchCreatePage();
    },[])

    const fetchCreatePage = () => {
        getApi('/admin/reseller/wallet/fund').then((response : any) => {
            let resellersList : any = [];
            response.data.data.resellers.forEach((reseller : any) => {
                resellersList.push({
                    label : reseller.resellerName,
                    value : reseller.resellerId
                })
            })
            setResellers(resellersList)
            let merchantsList : any = [];
            response.data.data.merchants.forEach((merchant : any) => {
                merchantsList.push({
                    label : merchant.merchantName,
                    value : merchant.merchantId
                })
            })
            setMerchants(merchantsList)
            let transactions : any = [];
            response.data.data.fundTransactions.forEach((transaction : any) => {
                transactions.push({
                    transactionId : transaction.transactionId,
                    transactionType : transaction.transactionType,
                    transactionSourceTransactionId : transaction.transactionSourceTransactionId,
                    transactionAmount : transaction.transactionAmount,
                    transactionAmountBefore : transaction.transactionAmountBefore,
                    transactionAmountAfter : transaction.transactionAmountAfter,
                    transactionDate : transaction.transactionDate,
                    transactionCurrency : transaction.transactionCurrency,
                    resellerName : transaction.resellerName,
                    resellerEmail : transaction.resellerEmail
                })
            })
            setTransactions(transactions)
        }).catch((error : any) => {
            toast.error(error.response.data.message);
        })
    }

    const handleAmount = (event : any) => {
        var amountValue : any = parseFloat(event.target.value)
        if(event.target.value === ''){
            amountValue = '';
        }
        setAmount(amountValue);
    }

    const handleMerchantAmount = (event : any) => {
        var amountValue : any = parseFloat(event.target.value)
        if(event.target.value === ''){
            amountValue = '';
        }
        setMerchantAmount(amountValue);
    }

    const handleSourceTransactionId = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const digitsOnly = value.replace(/\D/g, ''); // Only allow digits
        const sourceTransaction = digitsOnly ? parseFloat(digitsOnly) : 0;
        if (digitsOnly) {
            setSourceTransactionId(sourceTransaction);
        }
        if(!digitsOnly){
            setSourceTransactionId('');
        }
    };

    const handleMerchantSourceTransactionId = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const digitsOnly = value.replace(/\D/g, ''); // Only allow digits
        const sourceTransaction = digitsOnly ? parseFloat(digitsOnly) : 0;
        if (digitsOnly) {
            setMerchantSourceTransactionId(sourceTransaction);
        }
        if(!digitsOnly){
            setMerchantSourceTransactionId('');
        }
    };

    const handleReseller = (event : any) => {
        setReseller(event.value)
    }

    const handleMerchant = (event : any) => {
        setMerchant(event.value)
    }

    useEffect(() => {
        if(amount && sourceTransactionId && reseller){
            setDisabledFundButton(false)
        }else{
            setDisabledFundButton(true)
        }
    }, [amount,sourceTransactionId,reseller]);

    useEffect(() => {
        if(merchantAmount && merchantSourceTransactionId && merchant){
            setDisabledMerchantFundButton(false)
        }else{
            setDisabledMerchantFundButton(true)
        }
    }, [merchantAmount,merchantSourceTransactionId,merchant]);

    const fundWallet = () => {
        setLoading(true)
        const payload = {
            resellerId : reseller,
            amount : amount,
            sourceTransactionId : sourceTransactionId,
            currency : currency
        };
        postApi('/admin/reseller/wallet/fund',payload).then((response : any) => {
            setAmount('')
            setSourceTransactionId('')
            setCurrency('USD')
            toast.success(response.data.message);
            setLoading(false)
            setShowFundModel(true)
            fetchCreatePage();
        }).catch((error : any) => {
            toast.error('There was an issue while funding the requested amount');
            setShowFundModel(false)
            setLoading(false)
        })
    }

    function tog_center() {
        setShowFundModel(!showFundModel);
    }

    const fundMerchantWallet = () => {
        setLoading(true)
        const payload = {
            merchantId : merchant,
            amount : merchantAmount,
            sourceTransactionId : merchantSourceTransactionId,
            currency : currency
        };
        postApi('/admin/merchant/wallet/fund',payload).then((response : any) => {
            toast.success('Merchant wallet has been funded successfully');
            setMerchantAmount(0)
            setMerchantSourceTransactionId('')
            setMerchant(null)
            setLoading(false)
        }).catch((error : any) => {
            toast.error('There was an issue while funding the requested amount');
            setLoading(false)
        })
    }

    return <div className="page-content">
        <Container fluid={true}>
            <div className="rounded-3">
                <div className="row justify-content-center">
                    <div className="col-lg-9">
                        <div className="card h-100">
                            <div className="card-header">
                                Latest 10 Wallet Funds
                            </div>
                            <div className="table-responsive table-card card-body fw-bold">
                                <table className="table align-middle table-nowrap table-striped-columns mb-0">
                                    <thead className="table-light">
                                    <tr className="text-center">
                                        <th scope="col">Reseller Name</th>
                                        <th scope="col">Reseller Email</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Currency</th>
                                        <th scope="col">Amount Before</th>
                                        <th scope="col">Amount After</th>
                                        <th scope="col">Date</th>
                                    </tr>
                                    </thead>
                                    <tbody className="text-center">
                                    {transactions && transactions.length > 0 && transactions.map((transaction: any) => {
                                        return <tr>
                                            <td>{transaction.resellerName}</td>
                                            <td>{transaction.resellerEmail}</td>
                                            <td>{transaction.transactionAmount}</td>
                                            <td>{transaction.transactionCurrency}</td>
                                            <td>{transaction.transactionAmountBefore}</td>
                                            <td>{transaction.transactionAmountAfter}</td>
                                            <td>{transaction.transactionDate}</td>
                                        </tr>
                                    })}
                                    {transactions && transactions.length === 0 && <tr>
                                        <td className="text-center p-4" colSpan={7}>No Data Found</td>
                                    </tr>}
                                    {!transactions && <tr>
                                        <td className="text-center p-4" colSpan={7}><Spinner/></td>
                                    </tr>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <Nav tabs className="nav-tabs  nav-justified mb-3 bg-white">
                            <NavItem>
                                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "1", })} onClick={() => { toggle("1"); }} >
                                    Fund Reseller
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "2", })} onClick={() => { toggle("2"); }} >
                                    Fund Merchant
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab} className="text-muted">
                            <TabPane tabId="1" id="home">
                                <div className="card h-100">
                                    {resellers && <div className="card-body">
                                        <div className="mt-2">
                                            <h6>Select Reseller To Fund</h6>
                                            <Select
                                                options={resellers}
                                                id="choices-single-default"
                                                className="js-example-basic-single mb-0 z-2"
                                                name="merchant"
                                                onChange={handleReseller}
                                            />
                                        </div>
                                        <div className="mt-2">
                                            <Label>Fund Amount</Label>
                                            <div className="input-group">
                                                <ButtonGroup className="z-1">
                                                    <UncontrolledButtonDropdown id="btnGroupDrop1">
                                                        <DropdownToggle color="primary" caret size={'sm'}>
                                                            {currency}
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem onClick={() => {
                                                                setCurrency('USD')
                                                            }}> USD</DropdownItem>
                                                            <DropdownItem onClick={() => {
                                                                setCurrency('MAD')
                                                            }}> MAD </DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledButtonDropdown>
                                                </ButtonGroup>
                                                <Input type="number" min={0.01} max={9999} value={amount}
                                                       onChange={handleAmount}
                                                       className="form-control" id="autoSizingInputGroup"
                                                       placeholder="Fund Amount"/>
                                            </div>
                                        </div>
                                        <div className="mt-2">
                                            <Label>Bank Transaction ID Or USDT Transaction ID</Label>
                                            <div className="input-group">
                                                <Input type="text" value={sourceTransactionId}
                                                       onChange={handleSourceTransactionId}
                                                       className="form-control" id="autoSizingInputGroup"
                                                       placeholder="Source Transaction ID"/>
                                            </div>
                                        </div>
                                    </div>}
                                    {resellers && <div className={'card-footer'}>
                                        <div className={`mt-3 ${loading ? 'text-center' : ''}`}>
                                            {!loading &&
                                                <button className="btn btn-primary w-100" onClick={fundWallet}
                                                        disabled={disabledFundButton}>Fund Reseller Wallet</button>}
                                            {loading && <Spinner/>}
                                        </div>
                                    </div>}
                                    {!resellers && <div className="text-center m-5"><Spinner/></div>}
                                </div>
                            </TabPane>
                            <TabPane tabId="2" id="product">
                                <div className="card h-100">
                                    {merchants && <div className="card-body">
                                        <div className="mt-2">
                                            <h6>Select Merchant To Fund</h6>
                                            <Select
                                                options={merchants}
                                                id="choices-single-default"
                                                className="js-example-basic-single mb-0 z-2"
                                                name="merchant"
                                                onChange={handleMerchant}
                                            />
                                        </div>
                                        <div className="mt-2">
                                            <Label>Fund Amount</Label>
                                            <div className="input-group">
                                                <ButtonGroup className="z-1">
                                                    <UncontrolledButtonDropdown id="btnGroupDrop1">
                                                        <DropdownToggle color="primary" caret size={'sm'}>
                                                            {currency}
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem onClick={() => {
                                                                setCurrency('USD')
                                                            }}> USD</DropdownItem>
                                                            <DropdownItem onClick={() => {
                                                                setCurrency('MAD')
                                                            }}> MAD </DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledButtonDropdown>
                                                </ButtonGroup>
                                                <Input type="number" min={0.01} max={9999} value={merchantAmount}
                                                       onChange={handleMerchantAmount}
                                                       className="form-control" id="autoSizingInputGroup"
                                                       placeholder="Fund Amount"/>
                                            </div>
                                        </div>
                                        <div className="mt-2">
                                            <Label>Bank Transaction ID Or USDT Transaction ID</Label>
                                            <div className="input-group">
                                                <Input type="text" value={merchantSourceTransactionId}
                                                       onChange={handleMerchantSourceTransactionId}
                                                       className="form-control" id="autoSizingInputGroup"
                                                       placeholder="Source Transaction ID"/>
                                            </div>
                                        </div>
                                    </div>}
                                    {merchants && <div className={'card-footer'}>
                                        <div className={`mt-3 ${loading ? 'text-center' : ''}`}>
                                            {!loading &&
                                                <button className="btn btn-primary w-100" onClick={fundMerchantWallet}
                                                        disabled={disabledMerchantFundButton}>Fund Merchant Wallet</button>}
                                            {loading && <Spinner/>}
                                        </div>
                                    </div>}
                                    {!merchants && <div className="text-center m-5"><Spinner/></div>}
                                </div>
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
            </div>
        </Container>
    </div>
}

export default FundWallet;