import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback, Button, Spinner } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { registerUser, resetRegisterFlag } from "../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { createSelector } from "reselect";
import getApi from "../../apis/get.api";
import Select from "react-select";
import postApi from "../../apis/post.api";
import {state} from "sucrase/dist/types/parser/traverser/base";

const Register = () => {
    const [loader, setLoader] = useState<boolean>(false);
    const [disableCitiesSelect, setDisableCitiesSelect] = useState<boolean>(true);
    const [countries, setCountries] = useState<any>(null);
    const [cities, setCities] = useState<any>(null);
    const history = useNavigate();
    const dispatch : any = useDispatch();

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: '',
            username: '',
            country_id: '',
            city_id: '',
            address: '',
            zip_code: '',
            password: '',
            confirm_password: '',
            phone_number : '',
            tax_number : ''
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Your Email"),
            username: Yup.string().required("Please Enter Your Username"),
            country_id: Yup.string().required("Please Select Your Country"),
            city_id: Yup.string().required("Please Select Your City"),
            address: Yup.string().required("Please Enter Your Address"),
            zip_code: Yup.string().required("Please Enter Your Zip Code"),
            password: Yup.string().required("Please enter your password"),
            phone_number: Yup.string().required("Please enter company phone number"),
            confirm_password: Yup.string()
                .oneOf([Yup.ref("password")], "Passwords do not match")
                .required("Please confirm your password"),
        }),
        onSubmit: (values) => {
            dispatch(registerUser(values));
        }
    });

    const selectLayoutState = (state: any) => state.Account;
    const registerdatatype = createSelector(
        selectLayoutState,
        (account) => ({
            success: account.success,
            error: account.error
        })
    );

    const { error, success } = useSelector(registerdatatype);

    useEffect(() => {
        if (success) {
            setTimeout(() => history("/login"), 3000);
        }

        setTimeout(() => {
            dispatch(resetRegisterFlag());
        }, 3000);

    }, [dispatch, success, error, history]);

    document.title = "ClvrPay.com | Create Merchant Account";

    useEffect(() => {
        getApi('/common/get/countries').then((response) => {
            const countriesList = response.data.data.map((country: any) => ({
                label: country.name,
                value: country.id,
            }));
            setCountries(countriesList);
        }).catch((error) => {
            toast.error('General error occurred');
            throw error;
        });
    }, []);

    const changeCountry = (element: any) => {
        validation.setFieldValue("country_id", element.value);
        setDisableCitiesSelect(true);
        getApi('/common/get/cities/' + element.value).then((response) => {
            const citiesList = response.data.data.map((city: any) => ({
                label: city.name,
                value: city.id,
            }));
            setCities(citiesList);
        }).catch((error) => {
            toast.error('General error occurred');
            throw error;
        });
    };

    const changeCity = (element: any) => {
        validation.setFieldValue("city_id", element.value);
    };

    useEffect(() => {
        if (cities) {
            setDisableCitiesSelect(false);
        }
    }, [cities]);

    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img className="rounded-circle avatar-xl" alt="200x200"
                                                 src={process.env.REACT_APP_MERCHANT_URL + '/images/logo/clvrpay.jpeg'} />
                                        </Link>
                                    </div>
                                    <p className="mt-3 fs-15 fw-medium">Create Your Merchant Account</p>
                                </div>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="">
                                        <Form
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                            className="needs-validation" action="#">

                                            {success && success ? (
                                                <>
                                                    {toast("You will be redirected to the login page...", {
                                                        position: "top-right",
                                                        hideProgressBar: false,
                                                        className: 'bg-success text-white',
                                                        progress: undefined,
                                                        toastId: ""
                                                    })}
                                                    <ToastContainer autoClose={2000} limit={1}/>
                                                    <Alert color="success">
                                                        Registered Successfully! Redirecting to the login page...
                                                    </Alert>
                                                </>
                                            ) : null}

                                            {error && error ? (
                                                <Alert color="danger">
                                                    <div>Email has been registered before. Please use another email
                                                        address...
                                                    </div>
                                                </Alert>
                                            ) : null}

                                            <div className="mb-3">
                                                <Label htmlFor="useremail" className="form-label">Merchant Email <span
                                                    className="text-danger">*</span></Label>
                                                <Input
                                                    id="email"
                                                    name="email"
                                                    className="form-control"
                                                    placeholder="Enter email address"
                                                    type="email"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.email || ""}
                                                    invalid={
                                                        validation.touched.email && validation.errors.email ? true : false
                                                    }
                                                />
                                                {validation.touched.email && validation.errors.email ? (
                                                    <FormFeedback type="invalid">
                                                        <div>{validation.errors.email}</div>
                                                    </FormFeedback>
                                                ) : null}
                                            </div>

                                            <div className="mb-3">
                                                <Label htmlFor="username" className="form-label">Merchant Name <span
                                                    className="text-danger">*</span></Label>
                                                <Input
                                                    name="username"
                                                    type="text"
                                                    placeholder="Enter Merchant Name"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.username || ""}
                                                    invalid={
                                                        validation.touched.username && validation.errors.username ? true : false
                                                    }
                                                />
                                                {validation.touched.username && validation.errors.username ? (
                                                    <FormFeedback type="invalid">
                                                        <div>{validation.errors.username}</div>
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                            <div className="mb-3">
                                                <Label htmlFor="username" className="form-label">Merchant Phone
                                                    Number <span
                                                        className="text-danger">*</span></Label>
                                                <Input
                                                    name="phone_number"
                                                    type="text"
                                                    placeholder="Enter Phone Number"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.phone_number || ""}
                                                    invalid={
                                                        validation.touched.phone_number && validation.errors.phone_number ? true : false
                                                    }
                                                />
                                                {validation.touched.phone_number && validation.errors.phone_number ? (
                                                    <FormFeedback type="invalid">
                                                        <div>{validation.errors.phone_number}</div>
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                            <div className="mb-3">
                                                <Label htmlFor="username" className="form-label">Merchant Country <span
                                                    className="text-danger">*</span></Label>
                                                <Select
                                                    options={countries}
                                                    id="choices-single-default"
                                                    className="js-example-basic-single mb-0"
                                                    name="country_id"
                                                    onChange={changeCountry}
                                                    onBlur={validation.handleBlur}
                                                    value={countries?.find((option: any) => option.value === validation.values.country_id) || ""}
                                                />
                                                {validation.touched.country_id && validation.errors.country_id ? (
                                                    <div className="invalid-feedback d-block">
                                                        {validation.errors.country_id}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="mb-3">
                                                <Label htmlFor="username" className="form-label">Merchant City <span
                                                    className="text-danger">*</span></Label>
                                                <Select
                                                    options={cities}
                                                    isDisabled={disableCitiesSelect}
                                                    id="choices-single-default"
                                                    className="js-example-basic-single mb-0"
                                                    name="city_id"
                                                    onChange={changeCity}
                                                    onBlur={validation.handleBlur}
                                                    value={cities?.find((option: any) => option.value === validation.values.city_id) || ""}
                                                />
                                                {validation.touched.city_id && validation.errors.city_id ? (
                                                    <div className="invalid-feedback d-block">
                                                        {validation.errors.city_id}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="mb-3">
                                                <Label htmlFor="username" className="form-label">Merchant Address <span
                                                    className="text-danger">*</span></Label>
                                                <Input
                                                    name="address"
                                                    type="text"
                                                    placeholder="Enter address"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.address || ""}
                                                    invalid={
                                                        validation.touched.address && validation.errors.address ? true : false
                                                    }
                                                />
                                                {validation.touched.address && validation.errors.address ? (
                                                    <FormFeedback type="invalid">
                                                        <div>{validation.errors.address}</div>
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                            <div className="mb-3">
                                                <Label htmlFor="zip_code" className="form-label">Merchant Zip Code <span
                                                    className="text-danger">*</span></Label>
                                                <Input
                                                    name="zip_code"
                                                    type="text"
                                                    placeholder="Enter zip code"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.zip_code || ""}
                                                    invalid={
                                                        validation.touched.zip_code && validation.errors.zip_code ? true : false
                                                    }
                                                />
                                                {validation.touched.zip_code && validation.errors.zip_code ? (
                                                    <FormFeedback type="invalid">
                                                        <div>{validation.errors.zip_code}</div>
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                            <div className="mb-3">
                                                <Label htmlFor="userpassword" className="form-label">Password <span
                                                    className="text-danger">*</span></Label>
                                                <Input
                                                    name="password"
                                                    type="password"
                                                    placeholder="Enter Password"
                                                    autoComplete={''}
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.password || ""}
                                                    invalid={
                                                        validation.touched.password && validation.errors.password ? true : false
                                                    }
                                                />
                                                {validation.touched.password && validation.errors.password ? (
                                                    <FormFeedback type="invalid">
                                                        <div>{validation.errors.password}</div>
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                            <div className="mb-2">
                                                <Label htmlFor="confirmPassword" className="form-label">Confirm
                                                    Password <span className="text-danger">*</span></Label>
                                                <Input
                                                    name="confirm_password"
                                                    type="password"
                                                    autoComplete={''}
                                                    placeholder="Confirm Password"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.confirm_password || ""}
                                                    invalid={
                                                        validation.touched.confirm_password && validation.errors.confirm_password ? true : false
                                                    }
                                                />
                                                {validation.touched.confirm_password && validation.errors.confirm_password ? (
                                                    <FormFeedback type="invalid">
                                                        <div>{validation.errors.confirm_password}</div>
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                            <div className="mt-4">
                                                <Button color="success" className="w-100" type="submit"
                                                        disabled={loader && true}>
                                                    {loader &&
                                                        <Spinner size="sm" className='me-2'> Loading... </Spinner>}
                                                    Sign Up
                                                </Button>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                                <div className="mt-4 text-center">
                                    <p className="mb-0">Already have an account? <Link to="/login" className="fw-semibold text-primary text-decoration-underline"> Signin </Link>
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default Register;
