import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {Col, Container, Input, Label, Nav, NavItem, NavLink, Row, Spinner, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import { MerchantInterface } from 'Interfaces/Interface';
import getApi from 'apis/get.api';
import { handleError, handleSuccess } from 'helpers/commonFunctions';
import Loader from 'Components/Common/Loader';
import Counter from './merchantComponents/Counter';
import VoucherTotalReport from './merchantComponents/VoucherTotalReport';
import ResellersWalletFund from './merchantComponents/ResellersWalletFund';
import VouchersReport from './merchantComponents/VouchersReport';
import Settlements from './merchantComponents/Settlements';
import PendingSettlementRequests from './merchantComponents/PendingSettlementRequests';
import Vouchers from './merchantComponents/Vouchers';
import putApi from 'apis/put.api';
import Edit from './merchantComponents/Edit';

const EditMerchantPage = () => {
    document.title = "Admin | View Merchant";
    const { id } = useParams();
    const [activeTab, setActiveTab] = useState('1');
    const [activityTab, setActivityTab] = useState('1');
    const [defaultSettle, setDefaultSettle] = useState<any>(null);
    const [defaultWalletType, setDefaultWalletType] = useState<any>(null);
    const [defaultRestrictReseller, setDefaultRestrictReseller] = useState<any>(null);
    const [defaultCountry, setDefaultCountry] = useState<any>();
    const [countries, setCountries] = useState<any>(null);
    const [responseData,setResponseData] = useState<any>(null);
    const [merchantInformation, setMerchantInformation] = useState<MerchantInterface>({
        merchantId : 0,
        merchantStatus : '',
        merchantUsername: "",
        merchantEmail: "",
        merchantCountryId: 0,
        merchantCountryName : "",
        merchantWebsiteUrl: "",
        merchantPhoneNumber: "",
        merchantCommission: 0,
        merchantPayoutCommission : 0,
        merchantBalance : 0,
        merchantAutoSettleNoneClvrPay : 0,
        merchantPayoutWalletType : 1,
        merchantPayoutRestrictResellers : 0
    });

    const toggleTab = (tab : any) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const toggleActivityTab = (tab : any) => {
        if (activityTab !== tab) {
            setActivityTab(tab);
        }
    };

    useEffect(() => {
        getApi(`/admin/merchant/${id}`).then((response: any) => {
            const profileData = response.data.data.merchant;
            setResponseData(response.data.data)
            setMerchantInformation(profileData);
            setDefaultSettle({
                label : profileData.merchantAutoSettleNoneClvrPay == '0' ? 'No' : 'Yes',
                value :  profileData.merchantAutoSettleNoneClvrPay
            })
            setDefaultWalletType({
                label : profileData.merchantPayoutWalletType == 1 ? 'MAIN_WALLET' : 'PRE_LOADED_BALANCE_WALLET',
                value :  profileData.merchantPayoutWalletType
            })
            setDefaultRestrictReseller({
                label : profileData.merchantPayoutRestrictResellers == 1 ? 'Yes' : 'No',
                value :  profileData.merchantPayoutRestrictResellers
            })
            getApi('/common/get/countries').then((response: any) => {
                const countriesList = response.data.data.map((country: any) => ({
                    label: country.name,
                    value: country.id,
                }));
                countriesList.forEach((country: any) => {
                    if (country.value === profileData.merchantCountryId) {
                        setDefaultCountry({
                            label: country.label,
                            value: country.value // Correct the value here too
                        });
                    }
                });
                setCountries(countriesList);
            }).catch((error) => {
                handleError(error)
            })
        })
        .catch((error: any) => {
            handleError(error)
        });
    }, []);

    return <React.Fragment>
        <div className="page-content">
            {!responseData && <Loader/>}
            {responseData && <Container fluid>
                <div className="profile-foreground position-relative mx-n4 mt-n4">
                    <div className="profile-wid-bg">
                    </div>
                </div>
                <div className="pt-4 mb-4 mb-lg-3 pb-lg-4">
                    <div className="row justify-content-between">
                        <div className='col-lg-2'>
                            <div className="p-2">
                                <h3 className="text-white mb-1">{merchantInformation.merchantUsername}</h3>
                                <div className="hstack text-white-50 gap-1">
                                    <div className="me-2"><i className="ri-map-pin-user-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>
                                        {merchantInformation.merchantCountryName}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 text-white text-end">
                            <div className="p-2">
                            {merchantInformation.merchantEmail}
                            </div>
                        </div>
                    </div>
                </div>
                <Row>
                    <Col lg={12}>
                        <div>
                            <div className="d-flex">
                                <Nav pills className="animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
                                    role="tablist">
                                    <NavItem>
                                        <NavLink href="#overview-tab"  className={classnames({ active: activeTab === '1' }, "fs-14")} onClick={() => { toggleTab  ('1'); }}
                                        >
                                            <i className="ri-airplay-fill d-inline-block d-md-none"></i> <span
                                                className="d-none d-md-inline-block">General Information</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            href="#settlements"
                                            className={classnames({ active: activeTab === '2' }, "fs-14")}
                                            onClick={() => { toggleTab('2'); }}
                                        >
                                            <i className="ri-list-unordered d-inline-block d-md-none"></i> <span
                                                className="d-none d-md-inline-block">Vouchers By Merchant Resellers</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            href="#settlements"
                                            className={classnames({ active: activeTab === '3' }, "fs-14")}
                                            onClick={() => { toggleTab('3'); }}
                                        >
                                            <i className="ri-list-unordered d-inline-block d-md-none"></i> <span
                                                className="d-none d-md-inline-block">Settlements</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            href="#settlements"
                                            className={classnames({ active: activeTab === '4' }, "fs-14")}
                                            onClick={() => { toggleTab('4'); }}
                                        >
                                            <i className="ri-list-unordered d-inline-block d-md-none"></i> <span
                                                className="d-none d-md-inline-block">Pending Settlements</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            href="#settlements"
                                            className={classnames({ active: activeTab === '5' }, "fs-14")}
                                            onClick={() => { toggleTab('5'); }}
                                        >
                                            <i className="ri-list-unordered d-inline-block d-md-none"></i> <span
                                                className="d-none d-md-inline-block">Edit Merchant</span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </div>

                            <TabContent activeTab={activeTab} className="pt-4">
                                <TabPane tabId="1">
                                    <div className='row'>
                                        <div className="col-xl-12">
                                            <Counter responseData={responseData} merchantInformation={merchantInformation}/>
                                        </div>
                                        <div className='col-lg-6 '>
                                            <VoucherTotalReport id={id} />
                                        </div>
                                        <div className='col-lg-6'>
                                            <ResellersWalletFund id={id} />
                                        </div>
                                        <div className='col-lg-12'>
                                            <VouchersReport id={id}/>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tabId="2">
                                    <Vouchers merchantId={id ?? 0}/>
                                </TabPane>
                                <TabPane tabId="3">
                                    <Settlements merchantId={id ?? 0}/>
                                </TabPane>
                                <TabPane tabId="4">
                                    <PendingSettlementRequests merchantId={id ?? 0}/>
                                </TabPane>
                                <TabPane tabId="5">
                                    <Edit defaultRestrictReseller={defaultRestrictReseller} defaultWalletType={defaultWalletType} defaultSettle={defaultSettle} countries={countries} defaultCountry={defaultCountry} setMerchantInformation={setMerchantInformation} id={id ?? 0} merchantInformation={merchantInformation} />
                                </TabPane>
                            </TabContent>
                        </div>
                    </Col>
                </Row>
            </Container>}
        </div>
    </React.Fragment>
};

export default EditMerchantPage;