import getApi from "apis/get.api"
import Bar from "Components/charts/Bar"
import { handleError } from "helpers/commonFunctions"
import { useEffect, useState } from "react"
import { Card, CardBody, Spinner } from "reactstrap"

const ResellersWalletFund = ({id} : any) => {
    const [selectedFundWalletFilter, setSelectedFundWalletFilter] = useState('1y');
    const [categories, setCategories] = useState<any>([]);
    const [values, setValues] = useState<any>([]);

    useEffect(() => {
        getApi(`/merchant/report/chart/resellers-wallet-fund/${id}`).then((response) => {
            setCategories(response.data.data.resellers);
            setValues(response.data.data.amount);
        }).catch((error) => {
            handleError(error);
        });
    },[])
    
    const filterFundWallet = (dateRange: string) => {
        const endDate = new Date();
        let startDate = new Date();
    
        switch (dateRange) {
            case '1w': // 1 week
                startDate.setDate(endDate.getDate() - 7);
                break;
            case '1m': // 1 month
                startDate.setMonth(endDate.getMonth() - 1);
                break;
            case '6m': // 6 months
                startDate.setMonth(endDate.getMonth() - 6);
                break;
            case '1y': // 1 year
                startDate.setFullYear(endDate.getFullYear() - 1);
                break;
            default:
                return;
        }
    
        setSelectedFundWalletFilter(dateRange);
    
        // Format dates to yyyy-mm-dd
        const formatDate = (date: Date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };
    
        const formattedStartDate = formatDate(startDate);
        const formattedEndDate = formatDate(endDate);
    
        console.log(`Start Date: ${formattedStartDate}`);
        console.log(`End Date: ${formattedEndDate}`);
    
        // Add your API call here if needed
        getApi(`/merchant/report/chart/resellers-wallet-fund/${id}?startDate=${formattedStartDate}&endDate=${formattedEndDate}`).then((response) => {
            setCategories(response.data.data.resellers);
            setValues(response.data.data.amount);
        }).catch((error) => {
            handleError(error);
        });
    };

    return <Card>
        <div className="border-1 align-items-center d-flex card-header">
            <h4 className="card-title mb-0 flex-grow-1">My Resellers Fund Chart</h4>
            <div className="d-flex gap-1">
                <button type="button" onClick={() => {
                    filterFundWallet('1w');
                }}
                        className={`btn ${selectedFundWalletFilter === '1w' ? 'btn-primary' : 'btn-soft-secondary'} btn-sm shadow-none`}>1W
                </button>
                <button type="button" onClick={() => {
                    filterFundWallet('1m');
                }}
                        className={`btn ${selectedFundWalletFilter === '1m' ? 'btn-primary' : 'btn-soft-secondary'} btn-sm shadow-none`}>1M
                </button>
                <button type="button" onClick={() => {
                    filterFundWallet('6m');
                }}
                        className={`btn ${selectedFundWalletFilter === '6m' ? 'btn-primary' : 'btn-soft-secondary'} btn-sm shadow-none`}>6M
                </button>
                <button type="button" onClick={() => {
                    filterFundWallet('1y');
                }}
                        className={`btn ${selectedFundWalletFilter === '1y' ? 'btn-primary' : 'btn-soft-secondary'} btn-sm shadow-none`}>1Y
                </button>
            </div>
        </div>
        <CardBody>
            {!categories  && <div className="">
                <Spinner/>
            </div>}
            {categories && categories.length > 0 && <Bar enableDataLabels={true} distributed={true} horizontal={true}
                    categories={categories} values={values}
                    dataColors='["--vz-primary", "--vz-secondary", "--vz-success", "--vz-info", "--vz-warning", "--vz-danger", "--vz-dark", "--vz-primary", "--vz-success", "--vz-secondary"]'/>}
        </CardBody>
    </Card>
}

export default ResellersWalletFund