import React, {useEffect, useState} from "react";
import getApi from "../../../../apis/get.api";
import {toast} from "react-toastify";
import {
    Button,
    ButtonGroup,
    DropdownItem,
    DropdownMenu,
    DropdownToggle, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader,
    Pagination,
    PaginationItem,
    PaginationLink,
    Spinner,
    UncontrolledButtonDropdown
} from "reactstrap";
import {PendingSettlementRequestInterface} from "../../../../Interfaces/Interface";
import Loader from "../../../../Components/Common/Loader";
import putApi from "../../../../apis/put.api";
import { handleError } from "helpers/commonFunctions";

interface PageProps {
    merchantId: number | string
}

const PendingSettlementRequests = ({merchantId} : PageProps) => {
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [selectedRequest, setSelectedRequest] = useState<PendingSettlementRequestInterface>();
    const [showApproveModal, setShowApproveModal] = useState(false);
    const [approveActionLoader, setApproveActionLoader] = useState(false);
    const [amount,setAmount] = useState<number>(0)
    const [balance,setBalance] = useState<number>(0)
    const [madBalance,setMadBalance] = useState<number>(0)
    const [showDeactivateModal, setShowDeactivateModal] = useState(false);
    const [rejectionActionLoader, setRejectionActionLoader] = useState(false);

    const fetchPendingRequests = (page: number) => {
        setLoading(true);
        let url = `/admin/merchant/wallet/settlements/pending/${merchantId}?page=${page}&per_page=20`;
        getApi(url)
        .then((response) => {
            const data = response.data.data;
            setList(data.data);
            setCurrentPage(data.current_page);
            setLastPage(data.last_page);
        })
        .catch((error) => {
            handleError(error)
        })
        .finally(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        fetchPendingRequests(currentPage);
    }, [currentPage]);

    const handlePageChange = (page : number) => {
        if (page !== currentPage) {
            setCurrentPage(page);
        }
    };

    const selectRequest = (request : PendingSettlementRequestInterface) => {
        getApi('/admin/merchant/wallet/' + merchantId).then(response => {
            setBalance(response.data.data.balance)
            setMadBalance(response.data.data.balance_mad)
            setSelectedRequest(request);
            setShowApproveModal(!showApproveModal);
        }).catch(error => {
            toast.error('There was an issue while opening the action popup')
        })
    }

    const selectRequestToReject = (request : PendingSettlementRequestInterface) => {
        setSelectedRequest(request);
        setShowDeactivateModal(!showDeactivateModal);
    }

    function handleShowApprovePopup() {
        setShowApproveModal(!showApproveModal);
    }

    const approve = () => {
        setApproveActionLoader(true)
        putApi(`/admin/merchant/settle/approve/${merchantId}/${selectedRequest?.settlementRequestId}`,{})
        .then((response) => {
            if(response.data.code === 10000){
                toast.success('Settlement has been approved');
                setApproveActionLoader(false);
                setShowApproveModal(false);
                fetchPendingRequests(currentPage);
            }else{
                setApproveActionLoader(false)
                toast.error('There was an issue while processing your request');
            }
        })
        .catch((error) => {
            setApproveActionLoader(false)
            toast.error('There was an issue while processing your request');
        })
    }

    const reject = () => {
        setRejectionActionLoader(true)
        putApi(`/admin/merchant/settle/reject/${merchantId}/${selectedRequest?.settlementRequestId}`,{})
        .then((response) => {
            if(response.data.code === 10000){
                toast.success('Settlement has been rejected');
                setRejectionActionLoader(false);
                setShowDeactivateModal(false);
                fetchPendingRequests(currentPage);
            }else{
                setRejectionActionLoader(false)
                toast.error('There was an issue while processing your request');
            }
        })
        .catch((error) => {
            setRejectionActionLoader(false)
            toast.error('There was an issue while processing your request');
        })
    }

    const handleAmount = (event : any) => {
        var amountValue : any = parseInt(event.target.value)
        if(event.target.value === ''){
            amountValue = '';
        }
        setAmount(amountValue);
    }

    function handleShowDeactivatePopup() {
        setShowDeactivateModal(!showDeactivateModal);
    }

    return <div className="card">
        <div className="card-body">
            <div className="table-responsive table-card">
                <table className="table text-center align-middle table-nowrap table-striped-columns mb-0">
                    <thead className="table-light">
                    <tr>
                        <th scope="col" className={"text-center"}>Request ID</th>
                        <th scope="col">Merchant ID</th>
                        <th scope="col">Merchant Name</th>
                        <th scope="col">Merchant Email</th>
                        <th scope="col">Request Method</th>
                        <th scope="col">Request Amount</th>
                        <th scope="col">Request Currency</th>
                        <th scope="col">Request Date</th>
                        <th scope="col">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {loading && (
                        <tr>
                            <td className="text-center p-4" colSpan={9}><Spinner/></td>
                        </tr>
                    )}
                    {list && list.length > 0 && list.map((transaction: PendingSettlementRequestInterface) => (
                        <tr key={transaction.settlementRequestId} className={"fw-bold"}>
                            <td className={"text-center"}><span
                                className="fw-medium">#{transaction.settlementRequestId}</span>
                            </td>
                            <td>{transaction.settlementRequestMerchantId}</td>
                            <td>{transaction.settlementRequestMerchantUsername}</td>
                            <td>{transaction.settlementRequestMerchantEmail}</td>
                            <td>{transaction.settlementRequestMethod.toUpperCase()}</td>
                            <td>{transaction.settlementRequestAmount}</td>
                            <td>{transaction.settlementRequestCurrency}</td>
                            <td>{transaction.settlementRequestCreationDate}</td>
                            <td>
                                <ButtonGroup>
                                    <UncontrolledButtonDropdown id="btnGroupDrop1">
                                        <DropdownToggle color="primary" caret size={'sm'}>
                                            Request Actions
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={() => {
                                                selectRequest(transaction)
                                            }}>APPROVE</DropdownItem>
                                            <DropdownItem onClick={() => {
                                                selectRequestToReject(transaction)
                                            }}>REJECT</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </ButtonGroup>
                            </td>
                        </tr>
                    ))}
                    {!loading && list.length === 0 && (
                        <tr>
                            <td className="text-center p-4" colSpan={9}>No Transactions Found</td>
                        </tr>
                    )}
                    </tbody>
                </table>
                <div className="d-flex justify-content-center mt-3">
                    <Pagination>
                        <PaginationItem disabled={currentPage === 1}>
                            <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)}/>
                        </PaginationItem>
                        {[...Array(lastPage)].map((_, i) => (
                            <PaginationItem key={i + 1} active={currentPage === i + 1}>
                                <PaginationLink onClick={() => handlePageChange(i + 1)}>
                                    {i + 1}
                                </PaginationLink>
                            </PaginationItem>
                        ))}
                        <PaginationItem disabled={currentPage === lastPage}>
                            <PaginationLink next onClick={() => handlePageChange(currentPage + 1)}/>
                        </PaginationItem>
                    </Pagination>
                </div>
                <Modal isOpen={showApproveModal} toggle={handleShowApprovePopup} centered>
                    <ModalHeader className="border-1 p-2">
                        Settlement Request Details
                    </ModalHeader>
                    <ModalBody className="">
                        {!approveActionLoader && <div className="">
                            <div className="">
                                <Label>Requested Amount</Label>
                                <div className="input-group">
                                    <div className="input-group-text">{selectedRequest?.settlementRequestCurrency}</div>
                                    <Input type="number" min={1} max={9999} readOnly={true} disabled={true}
                                        value={selectedRequest?.settlementRequestAmount} onChange={handleAmount}
                                        className="form-control"/>
                                </div>
                            </div>
                            <div className="mt-2">
                                <Label>Requested Merchant ID</Label>
                                <div className="input-group">
                                    <Input type="text" readOnly={true} disabled={true}
                                        value={selectedRequest?.settlementRequestMerchantId}
                                        className="form-control"/>
                                </div>
                            </div>
                            <div className="mt-2">
                                <Label>Requested Merchant Email</Label>
                                <div className="input-group">
                                    <Input type="text" readOnly={true} disabled={true}
                                        value={selectedRequest?.settlementRequestMerchantEmail}
                                        className="form-control"/>
                                </div>
                            </div>
                            <div className="mt-2">
                                <Label>Request Creation Date</Label>
                                <div className="input-group">
                                    <Input type="text" readOnly={true} disabled={true}
                                        value={selectedRequest?.settlementRequestCreationDate}
                                        className="form-control"/>
                                </div>
                            </div>
                            <div className="mt-2">
                                <Label>Request Settlement Method</Label>
                                <div className="input-group">
                                    <Input type="text" readOnly={true} disabled={true}
                                        value={selectedRequest?.settlementRequestMethod.toUpperCase()}
                                        className="form-control"/>
                                </div>
                            </div>
                            <div className="mt-2">
                                <Label>Allowed Balance To Settle</Label>
                                <div className="input-group">
                                    <Input type="text" readOnly={true} disabled={true}
                                        value={madBalance}
                                        className="form-control"/>
                                </div>
                            </div>
                        </div>}
                        {approveActionLoader && <Loader/>}
                    </ModalBody>
                    {selectedRequest && <ModalFooter className="border-1 p-2">
                        {((balance < selectedRequest.settlementRequestAmount && selectedRequest.settlementRequestCurrency === 'USD') || (madBalance < selectedRequest.settlementRequestAmount && selectedRequest.settlementRequestCurrency === 'MAD')) &&
                            <div className="shadow border-0 mb-xl-0 alert alert-danger fade show w-100" role="alert">
                                Merchant does not have enough balance to settle
                        </div>}
                        {!approveActionLoader && <button className="btn btn-primary w-100 mt-2" disabled={((balance < selectedRequest.settlementRequestAmount && selectedRequest.settlementRequestCurrency === 'USD') || (madBalance < selectedRequest.settlementRequestAmount && selectedRequest.settlementRequestCurrency === 'MAD'))} onClick={approve}>
                            Settle Request
                        </button>}
                    </ModalFooter>}
                </Modal>
                <Modal isOpen={showDeactivateModal} toggle={handleShowDeactivatePopup} centered>
                    <ModalBody className="text-center ">
                        {!rejectionActionLoader && <div className="mt-4">
                            <h4 className="mb-3">Are you sure?</h4>
                            <p className="text-muted mb-4">Are you sure you want to deactivate this voucher?</p>
                            <div className="hstack gap-2 justify-content-center">
                                <Button to="#" className="btn btn-danger fw-medium" onClick={handleShowDeactivatePopup}><i className="ri-close-line me-1 align-middle"></i> Close</Button>
                                <Button to="#" className="btn btn-primary" onClick={reject}>Reject</Button>
                            </div>
                        </div>}
                        {rejectionActionLoader && <Loader/>}
                    </ModalBody>
                </Modal>
            </div>
        </div>
    </div>
}

export default PendingSettlementRequests